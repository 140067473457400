<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Clientes</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <cliente-modal></cliente-modal>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10,25,50,{text:'Todos','value':-1}]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
              >
                <template slot="items" slot-scope="props" @click.native="editar(props.item)">
                    <td>{{ props.item.CdCpfCgcFormatado }}</td>
                    <td>{{ props.item.NmCliente }}</td>
                    <td>{{ props.item.cidade.NmCidade }}</td>
                    <td>{{ props.item.cidade.CdUf}}</td>
                    <td>
                      <v-btn
                        depressed
                        outline
                        icon
                        fab
                        dark
                        color="primary"
                        small
                        @click.native="editar(props.item)"
                      >
                        <v-icon>find_in_page</v-icon>
                      </v-btn>
                    </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import toastr from "toastr";
import ClienteModal from '@/pages/comercial/Cliente.vue'
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

export default {
  name: "Clientes",
  components: {
    ClienteModal
  },
  data() {
    return {
      dialog: false,
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "CdCpfCgc",
            value: "CdCpfCgc",
          },
          {
            text: "Cliente",
            value: "NmCliente",
          },
          {
            text: "Cidade",
            value: "cidade.NmCidade",
          },
          {
            text: "Estado",
            value: "cidade.CdUf",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getList() {
      this.complex.items = [];
      toastr.success(
        "Atualizando Lista de Clientes...",
        "Varejão da Sucata"
      );
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/cliente")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      this.$store.commit('abrirModalCliente',item)
      window.getApp.$emit('APP_PRECO_PADRAO_CLIENTE_GET')
      window.getApp.$emit('APP_TIPO_SUCATA_SAIDA_GET')
      window.getApp.$emit('APP_CLINTES_BALANCAS_GET')
      window.getApp.$emit('APP_ITENS_SUCATA_GET')
    },
    excluir(item) {},
  },
  created() {
    this.getList()
    // window.getApp.$emit('APP_COMPRADORES_GET')
    // console.log(window.getApp._events)
    delete window.getApp._events.APP_PRECOS_SUCATA_UPDATED
    delete window.getApp._events.APP_RECORD_UPDATED
    window.getApp.$on('APP_CLIENTES_UPDATED',this.getList)
    window.getApp.$on('APP_RECORD_UPDATED',this.getList)
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt)
    }
  },
};
</script>