<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Notas Fiscais de Saída (Sem exibir Notas Complementares)</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <mtr-modal></mtr-modal>
              <mtr-view></mtr-view>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                :pagination.sync="complex.pagination"
                v-model="complex.selected"
              >
                <template
                  slot="items"
                  slot-scope="props"
                  :options="complex.options"
                  @click.native="editar(props.item)"
                >
                  <td>{{ props.item.NrNf }}</td>
                  <td>{{ props.item.NmCliente }}</td>
                  <td>{{ props.item.DtSaida | formatDate("DD/MM/Y") }}</td>
                  <td>{{ props.item.VlNf | formatNumber }}</td>
                  <td>{{ props.item.NmTransportadora }}</td>
                  <td>
                    <v-tooltip top v-if="props.item.FlXmlEnviado">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">mdi-email</v-icon>
                      </template>
                      <span>Email Enviado</span>
                    </v-tooltip>

                    <v-tooltip top v-if="!props.item.FlXmlEnviado">
                      <template v-slot:activator="{ on }">
                        <v-icon color="default" v-on="on">hourglass_top</v-icon>
                      </template>
                      <span>Aguardando Envio</span>
                    </v-tooltip>

                    <!-- Retorno -->
                    <v-tooltip top v-if="props.item.CdRetorno == '100'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">check_circle</v-icon>
                      </template>
                      <span>NF-e Autorizado ({{ props.item.CdRetorno }})</span>
                    </v-tooltip>

                    <v-tooltip top v-if="props.item.CdRetorno == '101'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="red" v-on="on">cancel</v-icon>
                      </template>
                      <span>NF-e Cancelada ({{ props.item.CdRetorno }})</span>
                    </v-tooltip>

                    <v-tooltip top v-if="props.item.CdRetorno == '102'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="red" v-on="on">report_off</v-icon>
                      </template>
                      <span>NF-e Inutilizada ({{ props.item.CdRetorno }})</span>
                    </v-tooltip>

                    <v-tooltip top v-if="props.item.CdRetorno == '302'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="red" v-on="on">block</v-icon>
                      </template>
                      <span>NF-e Denegada ({{ props.item.CdRetorno }})</span>
                    </v-tooltip>

                    <v-tooltip top v-if="props.item.CdRetorno == '204'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="red" v-on="on">content_copy</v-icon>
                      </template>
                      <span>NF-e em Duplicidade ({{ props.item.CdRetorno }})</span>
                    </v-tooltip>

                    <!-- <v-tooltip top v-if="props.item.FlCteExiste == '1'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">local_shipping</v-icon>
                      </template>
                      <span>CTE Autorizado</span>
                    </v-tooltip> -->
                  </td>

                  <td>
                    <div>
                      <v-tooltip top v-if="props.item.FlEmitirMDF != '1'">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            depressed
                            outline
                            icon
                            fab
                            dark
                            color="primary"
                            small
                            v-on="on"
                            @click.native="editar(props.item)"
                          >
                            <v-icon>open_in_browser</v-icon>
                          </v-btn>
                        </template>
                        <span>Enviar MTR</span>
                      </v-tooltip>
                      <!-- v-if="props.item.FlMTRGerado === '1'" -->
                      <v-tooltip top v-if="props.item.FlMTRGerado === '1'">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            depressed
                            outline
                            icon
                            fab
                            dark
                            color="primary"
                            small
                            v-on="on"
                            @click.native="verMtr(props.item)"
                          >
                            <v-icon>receipt_long</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver MTR</span>
                      </v-tooltip>

                      <danfe-view :item="props.item" textoBotao="Ver DANFE"></danfe-view>

                      <dacte-view
                        v-if="props.item.FlCteExiste == '1'"
                        :item="props.item"
                      ></dacte-view>

                      <mdf v-if="props.item.FlEmitirMDF == '1'" :item="props.item"></mdf>
                      <documentos v-if="props.item.FlEmitirMDF == '1'"></documentos>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import toastr from "toastr";
import MtrModal from "@/pages/comercial/MTR.vue";
import MtrView from "@/pages/comercial/MTRview.vue";
import DanfeView from "@/pages/contabilidade/DanfeView.vue";
import DacteView from "@/pages/comercial/DacteView.vue";
import Mdf from "@/pages/comercial/MDF.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
import Documentos from "@/pages/comercial/Documentos.vue";
// import PdfRenderer from "vue-pdf-renderer";
// import pdf from "vue-pdf";

moment.locale("pt-BR");

export default {
  name: "Saidas",
  components: {
    MtrModal,
    MtrView,
    DanfeView,
    DacteView,
    Mdf,
    Documentos,
    // PdfRenderer,
    // pdf,
  },
  data() {
    return {
      dialog: false,
      search: "",
      file: {},
      bin: {},
      exibirPDF: false,
      complex: {
        selected: [],
        pagination: {
          sortBy: "NrNf",
          descending: true,
        },
        headers: [
          {
            text: "NrNf",
            value: "NrNf",
            sortable: true,
          },
          {
            text: "Cliente",
            value: "NmCliente",
          },
          {
            text: "DtSaida",
            value: "DtSaida",
          },
          {
            text: "Valor",
            value: "VlNf",
          },
          {
            text: "Transportadora",
            value: "NmTrasportadora",
          },
          {
            text: "Status",
            value: "CdRetorno",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getList() {
      this.complex.items = [];
      toastr.success("Atualizando Lista de Notas Fiscais...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/NfSaidas")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      this.$store.commit("abrirModalMTR", item);
    },
    verDanfe(item) {
      toastr.success("Gerando a visualização do DANFE", "Varejão da Sucata");
      // window.getApp.$emit("APP_GET_DANFE", item.NrNf);
      this.$store.commit("abrirModalDanfe", item);
    },
    verMtr(item) {
      toastr.success("Gerando a visualização do MTR", "Varejão da Sucata");
      // window.getApp.$emit("APP_GET_DANFE", item.NrNf);
      this.$store.commit("abrirModalViewMTR", item);
    },
    enviarDanfe(item) {
      toastr.success("Enviado Danfe...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(
          this.$store.state.urlBase + "v1/comercial/DistribuirNFe/" + item.TxChAcessoNfe
        )
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    excluir(item) {},
  },
  created() {
    this.getList();
    delete window.getApp._events.APP_PRECOS_SUCATA_UPDATED;
    delete window.getApp._events.APP_RECORD_UPDATED;
    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      let valor = number.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
      return valor;
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>
