<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Notas Fiscais Emitidas</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                :pagination.sync="complex.pagination"
                v-model="complex.selected"
              >
                <template
                  slot="items"
                  slot-scope="props"
                  :options="complex.options"
                  @click.native="editar(props.item)"
                >
                  <!-- <td>{{ props.item.NSU }}</td> -->
                  <td width="10%">{{ props.item.TxChAcessoNfe }}</td>
                  <td>{{ props.item.destinatario }} - {{ props.item.cnpjFormated }}</td>
                  <td>
                    {{ props.item.DtEmissao | formatDate("DD/MM/Y hh:mm:ss") }}
                  </td>
                  <td>{{ props.item.NrNf }}</td>
                  <td>{{ props.item.NmSerieNf }}</td>
                  <!-- <td>{{ props.item.VlNf | formatNumber }}</td> -->
                  <!-- <td>{{ props.item.NmTransportadora }}</td> -->
                  <td>
                    <!-- Retorno -->
                    <v-tooltip top v-if="props.item.escriturada == '1'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">check_circle</v-icon>
                      </template>
                      <span>Escriturado</span>
                    </v-tooltip>

                    <v-tooltip top v-if="props.item.escriturada == '0'">
                      <template v-slot:activator="{ on }">
                        <v-icon color="red" v-on="on">cancel</v-icon>
                      </template>
                      <span>Não Escriturado</span>
                    </v-tooltip>
                    <!-- <span>{{ props.item.cSitCTe }}</span> -->
                    <br />
                    <span v-if="props.item.CdRetorno == '101'">NFe Cancelada</span>
                    <span v-if="props.item.CdRetorno == '100'">Autorizada</span>
                    <span v-if="props.item.CdRetorno == '102'">Inutilizada</span>
                    <span v-if="props.item.CdRetorno == '225'">Falha de XML</span>
                    <!-- {{ props.item.escriturada }} -->
                  </td>

                  <td>
                    <div>
                      <!-- <dacte-view :item="props.item"></dacte-view> -->
                      <!-- <danfe-view
                        :item="props.item"
                        v-if="props.item.saida"
                        textoBotao="Ver NFe Vinculada"
                      ></danfe-view> -->
                      <!-- <frete :item="props.item" v-if="!props.item.entrada"></frete> -->
                      <!-- <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            outline
                            icon
                            fab
                            small
                            color="primary"
                            @click.native="downloadXML(props.item)"
                            v-on="on"
                          >
                            <v-icon>archive</v-icon>
                          </v-btn>
                        </template>
                        <span>Download XML</span>
                      </v-tooltip> -->
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import toastr from "toastr";
import * as moment from "moment";
import DacteView from "@/pages/comercial/DacteView.vue";
import DanfeView from "@/pages/contabilidade/DanfeView.vue";
import Frete from "@/components/contabilidade/Frete.vue";
import "moment/locale/pt-br";

moment.locale("pt-BR");

export default {
  name: "Ctes",
  components: { DacteView, DanfeView, Frete },
  data() {
    return {
      dialog: false,
      search: "",
      file: {},
      bin: {},
      exibirPDF: false,
      complex: {
        selected: [],
        pagination: {
          sortBy: "DtEmissao",
          descending: true,
        },
        headers: [
          {
            text: "Chave",
            value: "TxChAcessoNfe",
          },
          {
            text: "Destinatário",
            value: "destinatario",
          },
          {
            text: "Data de Emissão",
            value: "DtEmissao",
          },
          {
            text: "Número",
            value: "NrNf",
          },
          {
            text: "Série",
            value: "NmSerieNf",
          },
          {
            text: "Status",
            value: "CdRetorno",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    downloadXML(item) {
      var pseudoelement = document.createElement("a");

      var filename = item.chCTe + ".xml";
      var pseudoelement = document.createElement("a");
      var blob = new Blob([item.arquivo.xml], { type: "text/plain" });

      pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
      pseudoelement.setAttribute("download", filename);

      pseudoelement.dataset.downloadurl = [
        "text/plain",
        pseudoelement.download,
        pseudoelement.href,
      ].join(":");
      pseudoelement.draggable = true;
      pseudoelement.classList.add("dragout");

      pseudoelement.click();
    },
    getList() {
      this.complex.items = [];
      toastr.success("Atualizando Lista de NFes...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(this.$store.state.urlBase + "v1/contabilidade/emitidas/nfe")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      // this.$store.commit("abrirModalMTR", item);
    },
    verDanfe(item) {
      // toastr.success("Gerando a visualização do DANFE", "Varejão da Sucata");
      // window.getApp.$emit("APP_GET_DANFE", item.NrNf);
      // this.$store.commit("abrirModalDanfe", item);
    },
    verMtr(item) {
      // toastr.success("Gerando a visualização do MTR", "Varejão da Sucata");
      // window.getApp.$emit("APP_GET_DANFE", item.NrNf);
      // this.$store.commit("abrirModalViewMTR", item);
    },
    excluir(item) {},
  },
  created() {
    this.getList();
    // delete window.getApp._events.APP_PRECOS_SUCATA_UPDATED;
    delete window.getApp._events.APP_RECORD_UPDATED;

    if (!this.$store.state.loja.CdUf) {
      window.getApp.$emit("APP_LOJA_GET");
    }

    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      let valor = number.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
      return valor;
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>
