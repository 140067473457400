<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          outline
          icon
          fab
          dark
          color="primary"
          small
          v-on="on"
          :disabled="item.escriturada == '1'"
          @click.native="buscarParams()"
        >
          <!-- <v-icon>move_to_inbox</v-icon> -->
          <v-icon>how_to_vote</v-icon>
        </v-btn>
      </template>
      <span>{{ textoBotao }}</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="atualizar()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline"
          >Escriturar Nota Fiscal - {{ chave }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn dark outline icon fab small @click="downloadXML()" v-on="on">
              <v-icon>archive</v-icon>
            </v-btn>
          </template>
          <span>Download XML</span>
        </v-tooltip>
        <v-toolbar-items>
          <v-btn dark flat @click="editar()"
            ><span v-if="!abrirEdicao">Fechar Edição</span
            ><span v-if="abrirEdicao">Abrir Edição</span></v-btn
          >
          <v-btn dark flat @click="salvar()">Escriturar</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-card class="mb-3">
                <v-toolbar dark dense color="primary">
                  <v-toolbar-title class="subheading"
                    >Status da Nota Fiscal
                    <span v-if="statusNfeRetorno.xMotivo">
                      : {{ statusNfeRetorno.xMotivo }} - (Cód. Retorno :
                      {{ statusNfeRetorno.cStat }})
                    </span></v-toolbar-title
                  ></v-toolbar
                >
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                :disabled="true"
                label="Fornecedor"
                v-model="nfe.NmFornec"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-text-field
                :disabled="true"
                label="CPF/CNPJ"
                v-model="nfe.fornecedor.CdCpfCgc"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md1>
              <v-text-field
                label="Série *"
                v-model="nfe.NmSerieNf"
                :disabled="true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-text-field
                label="Número *"
                v-model="nfe.NrNf"
                :disabled="true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md1>
              <v-text-field
                label="Espécie *"
                v-model="nfe.CdEspecieNf"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <!-- Segunda Linhas -->
            <v-flex xs12 sm12 md1>
              <v-autocomplete
                :items="cfops"
                :disabled="abrirEdicao"
                item-text="CdCfo"
                item-value="CdCfo"
                label="CFOP *"
                v-model="nfe.CdCfo"
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-dialog
                :disabled="false"
                ref="dialogDataEmissao"
                v-model="modalDataEmissao"
                :return-value.sync="nfe.dtEmissao"
                persistent
                lazy
                full-width
                width="290px"
              >
                <v-text-field
                  :disabled="abrirEdicao"
                  slot="activator"
                  v-model="dtEmissaoFormatted"
                  label="Data de Emissão "
                  prepend-icon="event"
                  readonly
                  required
                ></v-text-field>
                <v-date-picker
                  v-model="nfe.dtEmissao"
                  :min="item.DtEmissao"
                  locale="pt-br"
                  scrollable
                  @change="$refs.dialogDataEmissao.save(nfe.dtEmissao)"
                  @input="dtEmissaoFormatted = formatDate(nfe.dtEmissao)"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalDataEmissao = false"
                    >Cancelar</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-dialog
                :disabled="false"
                ref="dialogDataEntrada"
                v-model="modalDataEntrada"
                :return-value.sync="nfe.dtEntrada"
                persistent
                lazy
                full-width
                width="290px"
              >
                <v-text-field
                  :disabled="abrirEdicao"
                  slot="activator"
                  v-model="dtEntradaFormatted"
                  label="Data de Entrada *"
                  prepend-icon="event"
                  readonly
                  required
                ></v-text-field>
                <v-date-picker
                  v-model="nfe.dtEntrada"
                  :min="item.dtEmissao"
                  locale="pt-br"
                  scrollable
                  @change="mudarDataEntrada(nfe.dtEntrada)"
                  @input="dtEntradaFormatted = formatDate(nfe.dtEntrada)"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalDataEntrada = false"
                    >Cancelar</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12 md1>
              <v-text-field
                label="% ICMS"
                v-model="nfe.VlAliqIcms"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md3>
              <v-dialog v-model="dialogCredito" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <div style="text-align: right">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" flat>
                      Gerar Crédito
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Gerar Crédito</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-checkbox
                        v-model="nfe.gerarCreditoICMS"
                        label="ICMS"
                        class="ma-0 pa-0 mt-2"
                        :disabled="abrirEdicao"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="nfe.gerarCreditoIPI"
                        :disabled="abrirEdicao"
                        class="ma-0 pa-0"
                        label="IPI"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="nfe.gerarCreditoPISCOFINS"
                        :disabled="abrirEdicao"
                        class="ma-0 pa-0"
                        label="PIS/COFINS"
                      ></v-checkbox>
                    </v-container>
                    <!-- <small>*indicates required field</small> -->
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default darken-1" text @click="dialogCredito = false">
                      Fechar
                    </v-btn>
                    <v-btn color="primary darken-1" text @click="dialogCredito = false">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-dialog v-model="dialogOutras" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <div style="text-align: right">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" flat>
                      Outras Opções
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Outras Opções</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-checkbox
                        v-model="nfe.importacaoRevenda"
                        label="Importação de Mercadoria p/ Revenda"
                        :disabled="abrirEdicao"
                        class="ma-0 pa-0"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="nfe.possuiCTE"
                        :disabled="abrirEdicao"
                        class="ma-0 pa-0"
                        label="Possui Conhecimento de Frete"
                      ></v-checkbox>
                    </v-container>
                    <!-- <small>*indicates required field</small> -->
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default darken-1" text @click="dialogOutras = false">
                      Fechar
                    </v-btn>
                    <v-btn color="primary darken-1" text @click="dialogOutras = false">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12 md8>
              <v-text-field
                label="Chave de Acesso"
                v-model="nfe.TxChAcessoNfe"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-autocomplete
                :items="indPres"
                item-text="descricao"
                :disabled="abrirEdicao"
                item-value="valor"
                label="Indicador de Presença"
                v-model="nfe.indPres"
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-card>
                <v-toolbar dark dense color="primary">
                  <v-toolbar-title class="subheading"
                    >Itens da Nota Fiscal</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        fab
                        small
                        @click=""
                        v-on="on"
                        :disabled="abrirEdicao"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar Item</span>
                  </v-tooltip>
                </v-toolbar>
                <v-card-text>
                  <v-data-table :headers="headers" :items="nfe.itens">
                    <template v-slot:items="props">
                      <td>{{ props.item.CdItem }}</td>
                      <td class="text-xs-right">{{ props.item.NmItem }}</td>
                      <td class="text-xs-right">{{ props.item.NmNCM }}</td>
                      <td class="text-xs-right">{{ props.item.CdUnidade }}</td>
                      <td class="text-xs-right">
                        {{ props.item.QtLiquido | formatNumber }}
                      </td>
                      <td class="text-xs-right">
                        {{ props.item.VlUnitario | formatMoney }}
                      </td>
                      <td class="text-xs-right">
                        {{ props.item.VlTotal | formatMoney }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.VlAliqIcms | formatPercent }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.VlAliqIpi | formatPercent }}
                      </td>
                      <td class="text-xs-right">
                        {{ props.item.VlIpi | formatMoney }}
                      </td>
                      <td class="text-xs-center">{{ 0 }}</td>
                      <td class="text-xs-right">{{ 0 | formatPercent }}</td>
                      <td class="text-xs-right">{{ 0 | formatMoney }}</td>
                      <td class="text-xs-right">{{ 0 | formatPercent }}</td>
                      <td class="text-xs-right">{{ 0 | formatMoney }}</td>
                      <td class="text-xs-right">{{ 0 | formatPercent }}</td>
                      <td class="text-xs-right">{{ 0 | formatMoney }}</td>
                      <td class="text-xs-right">{{ 0 }}</td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-card>
                <v-toolbar dark dense color="primary">
                  <v-toolbar-title class="subheading">Cálculo do Imposto</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Base ICMS"
                          v-model="nfe.vBC"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Valor ICMS"
                          v-model="nfe.vICMS"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Base ICMS Subst."
                          v-model="nfe.vBCST"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Valor ICMS Subst."
                          v-model="nfe.vST"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Base ICMS Antec."
                          v-model="nfe.baseICMSAntec"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Valor ICMS Antec."
                          v-model="nfe.icmsAntec"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Total Produtos"
                          v-model="nfe.vProd"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Desconto"
                          v-model="nfe.vDesc"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Vl. Frete"
                          v-model="nfe.vFrete"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Vl. ICMS Frete"
                          v-model="nfe.vlICMSFrete"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Vl. Seguro"
                          v-model="nfe.vSeg"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Vl. Outras Despesas"
                          v-model="nfe.vOutro"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Vl. Base IPI"
                          v-model="nfe.vlBaseIPI"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Valor IPI"
                          v-model="nfe.vlIPI"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-autocomplete
                          :items="modalidadesFrete"
                          item-text="descricao"
                          :disabled="abrirEdicao"
                          item-value="valor"
                          label="Frete"
                          v-model="nfe.CdFrete"
                          required
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 sm12 md2>
                        <v-text-field
                          label="Total da N. Fiscal"
                          v-model="nfe.vNF"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md10>
              <v-card>
                <v-toolbar dark dense color="primary">
                  <v-toolbar-title class="subheading"
                    >Informações Complementares</v-toolbar-title
                  >
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          label="Informações Complementares"
                          v-model="nfe.infCpl"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-card>
                <v-toolbar dark dense color="primary">
                  <v-toolbar-title class="subheading">Industrialização</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          label="Industrialização"
                          v-model="nfe.industrializacao"
                          :disabled="abrirEdicao"
                        ></v-text-field>
                      </v-flex> </v-layout
                  ></v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-card elevation="1" style="width: 100%" class="mt-3">
              <v-toolbar dark dense color="primary">
                <v-toolbar-title class="subheading">Fatura</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.eventos"
                        :disabled="abrirEdicao"
                        item-text="DsEvento"
                        item-value="CdEvento"
                        label="Evento *"
                        v-model="nfe.CdEvento"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.ccustos"
                        :disabled="abrirEdicao"
                        item-text="NmCCusto"
                        item-value="CdCCusto"
                        label="Centro de Custo *"
                        v-model="nfe.CdCCusto"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        label="Parcela"
                        v-model="nfe.parcela"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        label="Qtd. Dias"
                        v-model="nfe.quantidadeDias"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-dialog
                        :disabled="false"
                        ref="dialogDataVencimento"
                        v-model="modalDataVencimento"
                        :return-value.sync="nfe.dtVencimento"
                        persistent
                        lazy
                        full-width
                        width="290px"
                      >
                        <v-text-field
                          :disabled="abrirEdicao"
                          slot="activator"
                          v-model="dtVencimentoFormatted"
                          label="Vencimento *"
                          prepend-icon="event"
                          readonly
                          required
                        ></v-text-field>
                        <v-date-picker
                          v-model="nfe.dtVencimento"
                          :min="nfe.dtEmissao"
                          locale="pt-br"
                          scrollable
                          @change="$refs.dialogDataVencimento.save(nfe.dtVencimento)"
                          @input="dtVencimentoFormatted = formatDate(nfe.dtVencimento)"
                        >
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="modalDataEntrada = false"
                            >Cancelar</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        label="Valor da Parcela"
                        v-model="nfe.valorParcela"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        label="Percentual"
                        v-model="nfe.percentual"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.metodoPagamento"
                        item-text="descricao"
                        :disabled="abrirEdicao"
                        item-value="valor"
                        label="Forma de pagamento *"
                        v-model="nfe.CdFormaPagto"
                        required
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-card elevation="1" style="width: 100%" class="mt-3">
              <v-toolbar dark dense color="primary">
                <v-toolbar-title class="subheading">Apuração</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        label="Observação"
                        v-model="nfe.apuracao.observacao"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.apuracaoICMS"
                        item-text="descricao"
                        :disabled="abrirEdicao"
                        item-value="valor"
                        label="ICMS"
                        v-model="nfe.apuracao.icms"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.apuracaoICMS"
                        item-text="descricao"
                        :disabled="abrirEdicao"
                        item-value="valor"
                        label="IPI"
                        v-model="nfe.apuracao.ipi"
                        required
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card elevation="1" style="width: 100%" class="mt-3">
              <v-toolbar dark dense color="primary">
                <v-toolbar-title class="subheading"
                  >Classificação Tributária</v-toolbar-title
                >
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md3>
                      <v-autocomplete
                        :items="$store.state.impostonfe[1]"
                        item-text="DsImposto"
                        :disabled="abrirEdicao"
                        item-value="CdImposto"
                        label="ICMS"
                        v-model="nfe.classificacaoTributaria.icms"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-autocomplete
                        :items="$store.state.impostonfe[2]"
                        item-text="DsImposto"
                        :disabled="abrirEdicao"
                        item-value="CdImposto"
                        label="IPI"
                        v-model="nfe.classificacaoTributaria.ipi"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-autocomplete
                        :items="$store.state.impostonfe[3]"
                        item-text="DsImposto"
                        :disabled="abrirEdicao"
                        item-value="CdImposto"
                        label="PIS"
                        v-model="nfe.classificacaoTributaria.pis"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-autocomplete
                        :items="$store.state.impostonfe[4]"
                        item-text="DsImposto"
                        :disabled="abrirEdicao"
                        item-value="CdImposto"
                        label="COFINS"
                        v-model="nfe.classificacaoTributaria.cofins"
                        required
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
        <v-btn color="blue darken-1" flat @click.native="salvar()">Escriturar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import { format, unformat } from "@/components/VMoeda/utils";
export default {
  props: {
    item: {},
    textoBotao: String,
    usarChave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nfe: {
        apuracao: {
          observacao: "",
        },
        fornecedor: {
          CdCpfCgc: "",
        },
        classificacaoTributaria: {
          icms: "",
          ipi: "",
          pis: "",
          cofins: "",
        },
        itens: [],
      },
      statusNfeRetorno: {
        xMotivo: "",
      },
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      dialogCredito: false,
      dialogOutras: false,
      abrirEdicao: true,
      trans: "FP002NfM",
      cfops: [],
      dtEmissao: new Date().toISOString().substr(0, 10),
      dtEntrada: new Date().toISOString().substr(0, 10),
      dtVencimento: new Date().toISOString().substr(0, 10),
      modalDataEmissao: false,
      modalDataVencimento: false,
      modalDataEntrada: false,
      modalDataValidade: false,
      loading: false,
      headers: [
        {
          text: "Item",
          align: "center",
          sortable: true,
          value: "CdItem",
        },
        { text: "Descrição", value: "NmItem" },
        { text: "NCM", value: "ncm" },
        { text: "Und", value: "und", align: "center" },
        { text: "Qtd", value: "qtd", align: "right" },
        { text: "Valor Unit.", value: "valorUnit", align: "right" },
        { text: "Valor Total", value: "valorTotal", align: "right" },
        { text: "ICMS %", value: "pICMS", align: "center" },
        { text: "IPI %", value: "pIPI", align: "center" },
        { text: "Valor IPI", value: "vIPI", align: "right" },
        { text: "Adição", value: "adicao" },
        { text: "I. Imp %", value: "iimp", align: "right" },
        { text: "Valor I. Imp.", value: "viimp", align: "right" },
        { text: "PIS %", value: "pPIS", align: "right" },
        { text: "Valor PIS", value: "vPIS", align: "right" },
        { text: "Cofins %", value: "pCOFINS", align: "right" },
        { text: "Valor Cofins", value: "vCOFINS", align: "right" },
        { text: "Largura", value: "largura" },
      ],
      modalidadesFrete: [
        { descricao: "CIF", valor: "C" },
        { descricao: "FOB", valor: "F" },
        { descricao: "Terceiros", valor: "T" },
        { descricao: "Remetente", valor: "R" },
        { descricao: "Destinatário", valor: "D" },
        { descricao: "Sem Frete", valor: "S" },
      ],
      indPres: [
        { descricao: "0 - Não se aplica", valor: 0 },
        { descricao: "1 - Operação presencial", valor: 1 },
        { descricao: "2 - Operação não presencial, pela Internet", valor: 2 },
        { descricao: "3 - Operação não presencial, Teleatendimento", valor: 3 },
        {
          descricao: "4 - NFC-e em operação com entrega a domicílio",
          valor: 4,
        },
        {
          descricao: "5 - Operação presencial fora do estabelecimento",
          valor: 5,
        },
        { descricao: "9 - Operação não presencial, outros", valor: 9 },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      percent: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    chave() {
      // console.log(this.item);
      let chave = "";
      if (this.item.TxChAcessoNfe) {
        chave = this.item.TxChAcessoNfe;
      } else if (this.item.saida) {
        chave = this.item.saida.TxChAcessoNfe;
      } else if (this.item.chNFe) {
        chave = this.item.chNFe;
      }
      return chave;
    },
    dtEmissaoFormatted: {
      // getter
      get: function () {
        if (typeof this.dtEmissao !== "undefined") {
          return this.formatDate(new Date(this.dtEmissao).toISOString().substr(0, 10));
        }
      },
      // setter
      set: function (date) {
        this.dtEmissao = this.parseDate(date);
        return date;
      },
    },
    dtEntradaFormatted: {
      // getter
      get: function () {
        if (typeof this.dtEntrada !== "undefined") {
          return this.formatDate(new Date(this.dtEntrada).toISOString().substr(0, 10));
        }
      },
      // setter
      set: function (date) {
        this.dtEntrada = this.parseDate(date);
        return date;
      },
    },
    dtVencimentoFormatted: {
      // getter
      get: function () {
        if (typeof this.dtEmissao !== "undefined") {
          let data = new Date(this.dtEmissao);

          if (this.nfe.quantidadeDias) {
            data.setDate(data.getDate() + this.nfe.quantidadeDias);
            this.nfe.dtVencimento = data.toISOString().substr(0, 10);
          }

          return this.formatDate(data.toISOString().substr(0, 10));
        }
      },
      // setter
      set: function (date) {
        this.nfe.dtVencimento = this.parseDate(date);
        return date;
      },
    },
  },
  methods: {
    mudarDataEntrada(dtEntrada) {
      // console.log(dtEntrada);
      this.$refs.dialogDataEntrada.save(dtEntrada);
      this.nfe.DtEntrada = dtEntrada;
      // console.log(this.nfe);
    },
    salvar() {
      toastr.success("Escriturando NFE...", "Varejão da Sucata");
      let param = this.nfe;

      param.vProd = unformat(param.vProd, 2);
      param.vDesc = unformat(param.vDesc, 2);
      param.vFrete = unformat(param.vFrete, 2);
      param.vOutro = unformat(param.vOutro, 2);
      param.vSeg = unformat(param.vSeg, 2);
      param.vBC = unformat(param.vBC, 2);
      param.vICMS = unformat(param.vICMS, 2);
      param.vBCST = unformat(param.vBCST, 2);
      param.vST = unformat(param.vST, 2);
      param.vNF = unformat(param.vNF, 2);
      param.valorParcela = unformat(param.valorParcela, 2);

      //Zerados
      param.baseICMSAntec = unformat(0, 2);
      param.icmsAntec = unformat(0, 2);
      param.vlICMSFrete = unformat(0, 2);
      param.vlBaseIPI = unformat(0, 2);
      param.vlIPI = unformat(0, 2);
      param.industrializacao = unformat(0, 2);

      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      console.log(param);
      this.$http
        .put(this.$store.state.urlBase + "v1/contabilidade/escriturarNFE", param)
        .then((response) => {
          if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "Escriturar NFE");
            }
          } else {
            toastr.success(response.data.message, "Escriturar NFE");
            this.atualizar();
          }
        });
      this.dialog = false;
    },
    downloadXML() {
      var pseudoelement = document.createElement("a");
      var filename = this.item.chNFe + ".xml";
      var pseudoelement = document.createElement("a");
      var blob = new Blob([this.nfe.xml], { type: "text/plain" });
      pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
      pseudoelement.setAttribute("download", filename);
      pseudoelement.dataset.downloadurl = [
        "text/plain",
        pseudoelement.download,
        pseudoelement.href,
      ].join(":");
      pseudoelement.draggable = true;
      pseudoelement.classList.add("dragout");
      pseudoelement.click();
    },
    buscarParams() {
      // console.log(this.item);
      toastr.success("Buscando parâmetros para faturas...", "Varejão da Sucata");

      window.getApp.$emit("APP_IMPOSTONFE_GET");

      window.getApp.$emit("APP_GET_EVENTOS", this.trans);

      window.getApp.$emit("APP_METODO_PAGAMENTO_GET");

      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(
          this.$store.state.urlBase + "v1/contabilidade/consultaCFOPSelect/" + this.trans
        )
        .then((response) => {
          if (response.data) {
            this.cfops = response.data;
          }
        });
      this.$http
        .get(
          this.$store.state.urlBase +
            "v1/contabilidade/getEscrituraNFe/" +
            this.item.chNFe
        )
        .then((response) => {
          if (response.data.error) {
            toastr.error(response.data.error, "Escriturar NFe");
            this.atualizar();
          } else if (response.data.obj) {
            this.nfe = response.data;
            this.dtEmissao = new Date(this.nfe.DtEmissao).toISOString().substr(0, 10);
            this.dtEntrada = new Date(this.nfe.DtEntrada).toISOString().substr(0, 10);

            if (this.nfe.quantidadeDias) {
              let data = new Date(this.dtEntrada);
              data.setDate(data.getDate() + this.nfe.quantidadeDias);
              this.dtVencimento = data.toISOString().substr(0, 10);
            }

            this.nfe.vProd = format(this.nfe.vProd, this.money);
            this.nfe.vDesc = format(this.nfe.vDesc, this.money);
            this.nfe.vFrete = format(this.nfe.vFrete, this.money);
            this.nfe.vOutro = format(this.nfe.vOutro, this.money);
            this.nfe.vSeg = format(this.nfe.vSeg, this.money);
            this.nfe.vBC = format(this.nfe.vBC, this.money);
            this.nfe.vICMS = format(this.nfe.vICMS, this.money);
            this.nfe.vBCST = format(this.nfe.vBCST, this.money);
            this.nfe.vST = format(this.nfe.vST, this.money);
            this.nfe.vNF = format(this.nfe.vNF, this.money);
            this.nfe.valorParcela = format(this.nfe.valorParcela, this.money);

            window.getApp.$emit("APP_GET_CCUSTO", this.nfe.CdEvento);

            //Zerados
            this.nfe.baseICMSAntec = format(0, this.money);
            this.nfe.icmsAntec = format(0, this.money);
            this.nfe.vlICMSFrete = format(0, this.money);
            this.nfe.vlBaseIPI = format(0, this.money);
            this.nfe.vlIPI = format(0, this.money);
            this.nfe.industrializacao = format(0, this.money);

            //Itens
            this.nfe.itens = Object.values(this.nfe.itens);
            // $modFrete = (int)

            console.log(this.nfe);
          }
        });

      //Ajustando o Objeto
      this.nfe.TxChAcessoNfe = this.item.chNFe;
      this.montarDanfeLocal();
    },
    montarDanfeLocal() {
      let url = "";

      if (this.item.NrNf && this.usarChave === false) {
        let NrNF = parseInt(this.item.NrNf);
        url = this.$store.state.urlBase + "v1/sefaz/Danfe64/" + NrNF;
      } else if (this.item.saida && this.item.saida.NrNf && this.usarChave === false) {
        this.item.TxChAcessoNfe = this.item.saida.TxChAcessoNfe;
        url = this.$store.state.urlBase + "v1/sefaz/Danfe64/" + this.item.saida.NrNf;
      } else if (this.usarChave) {
        this.item.TxChAcessoNfe = this.item.chNFe;
        // console.log(this.item);
        url =
          this.$store.state.urlBase +
          "v1/sefaz/Danfe64ByChave/" +
          this.item.TxChAcessoNfe;
        // console.log(this.item);
      }
      this.statusNfeRetorno.xMotivo = "";
      let urlConsulta =
        this.$store.state.urlBaseWeb +
        "sefaz/NfeConsultaProtocolo/" +
        this.item.TxChAcessoNfe;
      this.$http.get(urlConsulta).then((response) => {
        this.statusNfeRetorno = response.data;
        if (this.statusNfeRetorno.cStat !== "100") {
          toastr.error(
            "Não é permitido escriturar uma NFe que não esteja autorizada! Status da NFe: " +
              this.statusNfeRetorno.xMotivo,
            "Escriturar NFe"
          );
          this.atualizar();
        }
      });

      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http.get(url).then((response) => {
        if (response.data.error) {
          toastr.error(response.data.error, "Escriturar NFe");
          this.atualizar();
        }
      });
    },
    editar() {
      this.abrirEdicao = !this.abrirEdicao;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2).toLocaleString("pt-BR");
    },
    atualizar() {
      this.param = {};
      window.getApp.$emit("APP_RECORD_UPDATED");

      this.dialog = false;
    },
  },
  filters: {
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
    formatMoney(value) {
      let money = {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      };
      return format(value, money);
    },
    formatNumber(value) {
      let money = {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      };
      return format(value, money);
    },
    formatPercent(value) {
      let money = {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "%",
        precision: 2,
        masked: false,
      };
      return format(value, money);
    },
  },
  updated() {
    // window.getApp.$emit("APP_GET_DANFE", this.$store.state.danfe.NrNf);
    // this.montarDanfeLocal();
    //console.log(this);
    // setTimeout(() => {
    //   this.montarDanfe();
    // }, 1);
  },

  mounted() {},
};
</script>
