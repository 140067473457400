export default [
  {
    'country': 'Aruba',
    'abbr': 'AW'
  },
  {
    'country': 'Afghanistan',
    'abbr': 'AF'
  },
  {
    'country': 'Angola',
    'abbr': 'AO'
  },
  {
    'country': 'Anguilla',
    'abbr': 'AI'
  },
  {
    'country': 'Albania',
    'abbr': 'AL'
  },
  {
    'country': 'Andorra',
    'abbr': 'AD'
  },
  {
    'country': 'Netherlands Antilles',
    'abbr': 'AN'
  },
  {
    'country': 'United Arab Emirates',
    'abbr': 'AE'
  },
  {
    'country': 'Argentina',
    'abbr': 'AR'
  },
  {
    'country': 'Armenia',
    'abbr': 'AM'
  },
  {
    'country': 'American Samoa',
    'abbr': 'AS'
  },
  {
    'country': 'Antarctica',
    'abbr': 'AQ'
  },
  {
    'country': 'French Southern territories',
    'abbr': 'TF'
  },
  {
    'country': 'Antigua and Barbuda',
    'abbr': 'AG'
  },
  {
    'country': 'Australia',
    'abbr': 'AU'
  },
  {
    'country': 'Austria',
    'abbr': 'AT'
  },
  {
    'country': 'Azerbaijan',
    'abbr': 'AZ'
  },
  {
    'country': 'Burundi',
    'abbr': 'BI'
  },
  {
    'country': 'Belgium',
    'abbr': 'BE'
  },
  {
    'country': 'Benin',
    'abbr': 'BJ'
  },
  {
    'country': 'Burkina Faso',
    'abbr': 'BF'
  },
  {
    'country': 'Bangladesh',
    'abbr': 'BD'
  },
  {
    'country': 'Bulgaria',
    'abbr': 'BG'
  },
  {
    'country': 'Bahrain',
    'abbr': 'BH'
  },
  {
    'country': 'Bahamas',
    'abbr': 'BS'
  },
  {
    'country': 'Bosnia and Herzegovina',
    'abbr': 'BA'
  },
  {
    'country': 'Belarus',
    'abbr': 'BY'
  },
  {
    'country': 'Belize',
    'abbr': 'BZ'
  },
  {
    'country': 'Bermuda',
    'abbr': 'BM'
  },
  {
    'country': 'Bolivia',
    'abbr': 'BO'
  },
  {
    'country': 'Brazil',
    'abbr': 'BR'
  },
  {
    'country': 'Barbados',
    'abbr': 'BB'
  },
  {
    'country': 'Brunei',
    'abbr': 'BN'
  },
  {
    'country': 'Bhutan',
    'abbr': 'BT'
  },
  {
    'country': 'Bouvet Island',
    'abbr': 'BV'
  },
  {
    'country': 'Botswana',
    'abbr': 'BW'
  },
  {
    'country': 'Central African Republic',
    'abbr': 'CF'
  },
  {
    'country': 'Canada',
    'abbr': 'CA'
  },
  {
    'country': 'Cocos (Keeling) Islands',
    'abbr': 'CC'
  },
  {
    'country': 'Switzerland',
    'abbr': 'CH'
  },
  {
    'country': 'Chile',
    'abbr': 'CL'
  },
  {
    'country': 'China',
    'abbr': 'CN'
  },
  {
    'country': 'Ivory Coast',
    'abbr': 'CI'
  },
  {
    'country': 'Cameroon',
    'abbr': 'CM'
  },
  {
    'country': 'Congo, The Democratic Republic of the',
    'abbr': 'CD'
  },
  {
    'country': 'Congo',
    'abbr': 'CG'
  },
  {
    'country': 'Cook Islands',
    'abbr': 'CK'
  },
  {
    'country': 'Colombia',
    'abbr': 'CO'
  },
  {
    'country': 'Comoros',
    'abbr': 'KM'
  },
  {
    'country': 'Cape Verde',
    'abbr': 'CV'
  },
  {
    'country': 'Costa Rica',
    'abbr': 'CR'
  },
  {
    'country': 'Cuba',
    'abbr': 'CU'
  },
  {
    'country': 'Christmas Island',
    'abbr': 'CX'
  },
  {
    'country': 'Cayman Islands',
    'abbr': 'KY'
  },
  {
    'country': 'Cyprus',
    'abbr': 'CY'
  },
  {
    'country': 'Czech Republic',
    'abbr': 'CZ'
  },
  {
    'country': 'Germany',
    'abbr': 'DE'
  },
  {
    'country': 'Djibouti',
    'abbr': 'DJ'
  },
  {
    'country': 'Dominica',
    'abbr': 'DM'
  },
  {
    'country': 'Denmark',
    'abbr': 'DK'
  },
  {
    'country': 'Dominican Republic',
    'abbr': 'DO'
  },
  {
    'country': 'Algeria',
    'abbr': 'DZ'
  },
  {
    'country': 'Ecuador',
    'abbr': 'EC'
  },
  {
    'country': 'Egypt',
    'abbr': 'EG'
  },
  {
    'country': 'Eritrea',
    'abbr': 'ER'
  },
  {
    'country': 'Western Sahara',
    'abbr': 'EH'
  },
  {
    'country': 'Spain',
    'abbr': 'ES'
  },
  {
    'country': 'Estonia',
    'abbr': 'EE'
  },
  {
    'country': 'Ethiopia',
    'abbr': 'ET'
  },
  {
    'country': 'Finland',
    'abbr': 'FI'
  },
  {
    'country': 'Fiji Islands',
    'abbr': 'FJ'
  },
  {
    'country': 'Falkland Islands',
    'abbr': 'FK'
  },
  {
    'country': 'France',
    'abbr': 'FR'
  },
  {
    'country': 'Faroe Islands',
    'abbr': 'FO'
  },
  {
    'country': 'Federated States of Micronesia',
    'abbr': 'FM'
  },
  {
    'country': 'Gabon',
    'abbr': 'GA'
  },
  {
    'country': 'United Kingdom',
    'abbr': 'GB'
  },
  {
    'country': 'Georgia',
    'abbr': 'GE'
  },
  {
    'country': 'Ghana',
    'abbr': 'GH'
  },
  {
    'country': 'Gibraltar',
    'abbr': 'GI'
  },
  {
    'country': 'Guinea',
    'abbr': 'GN'
  },
  {
    'country': 'Guadeloupe',
    'abbr': 'GP'
  },
  {
    'country': 'Gambia',
    'abbr': 'GM'
  },
  {
    'country': 'Guinea-Bissau',
    'abbr': 'GW'
  },
  {
    'country': 'Equatorial Guinea',
    'abbr': 'GQ'
  },
  {
    'country': 'Greece',
    'abbr': 'GR'
  },
  {
    'country': 'Grenada',
    'abbr': 'GD'
  },
  {
    'country': 'Greenland',
    'abbr': 'GL'
  },
  {
    'country': 'Guatemala',
    'abbr': 'GT'
  },
  {
    'country': 'French Guiana',
    'abbr': 'GF'
  },
  {
    'country': 'Guam',
    'abbr': 'GU'
  },
  {
    'country': 'Guyana',
    'abbr': 'GY'
  },
  {
    'country': 'Hong Kong',
    'abbr': 'HK'
  },
  {
    'country': 'Heard Island and McDonald Islands',
    'abbr': 'HM'
  },
  {
    'country': 'Honduras',
    'abbr': 'HN'
  },
  {
    'country': 'Croatia',
    'abbr': 'HR'
  },
  {
    'country': 'Haiti',
    'abbr': 'HT'
  },
  {
    'country': 'Hungary',
    'abbr': 'HU'
  },
  {
    'country': 'Indonesia',
    'abbr': 'ID'
  },
  {
    'country': 'India',
    'abbr': 'IN'
  },
  {
    'country': 'British Indian Ocean Territory',
    'abbr': 'IO'
  },
  {
    'country': 'Ireland',
    'abbr': 'IE'
  },
  {
    'country': 'Iran',
    'abbr': 'IR'
  },
  {
    'country': 'Iraq',
    'abbr': 'IQ'
  },
  {
    'country': 'Iceland',
    'abbr': 'IS'
  },
  {
    'country': 'Israel',
    'abbr': 'IL'
  },
  {
    'country': 'Italy',
    'abbr': 'IT'
  },
  {
    'country': 'Jamaica',
    'abbr': 'JM'
  },
  {
    'country': 'Jordan',
    'abbr': 'JO'
  },
  {
    'country': 'Japan',
    'abbr': 'JP'
  },
  {
    'country': 'Kazakhstan',
    'abbr': 'KZ'
  },
  {
    'country': 'Kenya',
    'abbr': 'KE'
  },
  {
    'country': 'Kyrgyzstan',
    'abbr': 'KG'
  },
  {
    'country': 'Cambodia',
    'abbr': 'KH'
  },
  {
    'country': 'Kiribati',
    'abbr': 'KI'
  },
  {
    'country': 'Saint Kitts and Nevis',
    'abbr': 'KN'
  },
  {
    'country': 'South Korea',
    'abbr': 'KR'
  },
  {
    'country': 'Kuwait',
    'abbr': 'KW'
  },
  {
    'country': 'Laos',
    'abbr': 'LA'
  },
  {
    'country': 'Lebanon',
    'abbr': 'LB'
  },
  {
    'country': 'Liberia',
    'abbr': 'LR'
  },
  {
    'country': 'Libyan Arab Jamahiriya',
    'abbr': 'LY'
  },
  {
    'country': 'Saint Lucia',
    'abbr': 'LC'
  },
  {
    'country': 'Liechtenstein',
    'abbr': 'LI'
  },
  {
    'country': 'Sri Lanka',
    'abbr': 'LK'
  },
  {
    'country': 'Lesotho',
    'abbr': 'LS'
  },
  {
    'country': 'Lithuania',
    'abbr': 'LT'
  },
  {
    'country': 'Luxembourg',
    'abbr': 'LU'
  },
  {
    'country': 'Latvia',
    'abbr': 'LV'
  },
  {
    'country': 'Macao',
    'abbr': 'MO'
  },
  {
    'country': 'Morocco',
    'abbr': 'MA'
  },
  {
    'country': 'Monaco',
    'abbr': 'MC'
  },
  {
    'country': 'Moldova',
    'abbr': 'MD'
  },
  {
    'country': 'Madagascar',
    'abbr': 'MG'
  },
  {
    'country': 'Maldives',
    'abbr': 'MV'
  },
  {
    'country': 'Mexico',
    'abbr': 'MX'
  },
  {
    'country': 'Marshall Islands',
    'abbr': 'MH'
  },
  {
    'country': 'Macedonia',
    'abbr': 'MK'
  },
  {
    'country': 'Mali',
    'abbr': 'ML'
  },
  {
    'country': 'Malta',
    'abbr': 'MT'
  },
  {
    'country': 'Myanmar',
    'abbr': 'MM'
  },
  {
    'country': 'Mongolia',
    'abbr': 'MN'
  },
  {
    'country': 'Northern Mariana Islands',
    'abbr': 'MP'
  },
  {
    'country': 'Mozambique',
    'abbr': 'MZ'
  },
  {
    'country': 'Mauritania',
    'abbr': 'MR'
  },
  {
    'country': 'Montserrat',
    'abbr': 'MS'
  },
  {
    'country': 'Martinique',
    'abbr': 'MQ'
  },
  {
    'country': 'Mauritius',
    'abbr': 'MU'
  },
  {
    'country': 'Malawi',
    'abbr': 'MW'
  },
  {
    'country': 'Malaysia',
    'abbr': 'MY'
  },
  {
    'country': 'Mayotte',
    'abbr': 'YT'
  },
  {
    'country': 'Namibia',
    'abbr': 'NA'
  },
  {
    'country': 'New Caledonia',
    'abbr': 'NC'
  },
  {
    'country': 'Niger',
    'abbr': 'NE'
  },
  {
    'country': 'Norfolk Island',
    'abbr': 'NF'
  },
  {
    'country': 'Nigeria',
    'abbr': 'NG'
  },
  {
    'country': 'Nicaragua',
    'abbr': 'NI'
  },
  {
    'country': 'Niue',
    'abbr': 'NU'
  },
  {
    'country': 'Netherlands',
    'abbr': 'NL'
  },
  {
    'country': 'Norway',
    'abbr': 'NO'
  },
  {
    'country': 'Nepal',
    'abbr': 'NP'
  },
  {
    'country': 'Nauru',
    'abbr': 'NR'
  },
  {
    'country': 'New Zealand',
    'abbr': 'NZ'
  },
  {
    'country': 'Oman',
    'abbr': 'OM'
  },
  {
    'country': 'Pakistan',
    'abbr': 'PK'
  },
  {
    'country': 'Panama',
    'abbr': 'PA'
  },
  {
    'country': 'Pitcairn',
    'abbr': 'PN'
  },
  {
    'country': 'Peru',
    'abbr': 'PE'
  },
  {
    'country': 'Philippines',
    'abbr': 'PH'
  },
  {
    'country': 'Palau',
    'abbr': 'PW'
  },
  {
    'country': 'Papua New Guinea',
    'abbr': 'PG'
  },
  {
    'country': 'Poland',
    'abbr': 'PL'
  },
  {
    'country': 'Puerto Rico',
    'abbr': 'PR'
  },
  {
    'country': 'North Korea',
    'abbr': 'KP'
  },
  {
    'country': 'Portugal',
    'abbr': 'PT'
  },
  {
    'country': 'Paraguay',
    'abbr': 'PY'
  },
  {
    'country': 'Palestine',
    'abbr': 'PS'
  },
  {
    'country': 'French Polynesia',
    'abbr': 'PF'
  },
  {
    'country': 'Qatar',
    'abbr': 'QA'
  },
  {
    'country': 'Reunion',
    'abbr': 'RE'
  },
  {
    'country': 'Romania',
    'abbr': 'RO'
  },
  {
    'country': 'Russian Federation',
    'abbr': 'RU'
  },
  {
    'country': 'Rwanda',
    'abbr': 'RW'
  },
  {
    'country': 'Saudi Arabia',
    'abbr': 'SA'
  },
  {
    'country': 'Sudan',
    'abbr': 'SD'
  },
  {
    'country': 'Senegal',
    'abbr': 'SN'
  },
  {
    'country': 'Singapore',
    'abbr': 'SG'
  },
  {
    'country': 'South Georgia and the South Sandwich Islands',
    'abbr': 'GS'
  },
  {
    'country': 'Saint Helena',
    'abbr': 'SH'
  },
  {
    'country': 'Svalbard and Jan Mayen',
    'abbr': 'SJ'
  },
  {
    'country': 'Solomon Islands',
    'abbr': 'SB'
  },
  {
    'country': 'Sierra Leone',
    'abbr': 'SL'
  },
  {
    'country': 'El Salvador',
    'abbr': 'SV'
  },
  {
    'country': 'San Marino',
    'abbr': 'SM'
  },
  {
    'country': 'Somalia',
    'abbr': 'SO'
  },
  {
    'country': 'Saint Pierre and Miquelon',
    'abbr': 'PM'
  },
  {
    'country': 'Sao Tome and Principe',
    'abbr': 'ST'
  },
  {
    'country': 'Suriname',
    'abbr': 'SR'
  },
  {
    'country': 'Slovakia',
    'abbr': 'SK'
  },
  {
    'country': 'Slovenia',
    'abbr': 'SI'
  },
  {
    'country': 'Sweden',
    'abbr': 'SE'
  },
  {
    'country': 'Swaziland',
    'abbr': 'SZ'
  },
  {
    'country': 'Seychelles',
    'abbr': 'SC'
  },
  {
    'country': 'Syria',
    'abbr': 'SY'
  },
  {
    'country': 'Turks and Caicos Islands',
    'abbr': 'TC'
  },
  {
    'country': 'Chad',
    'abbr': 'TD'
  },
  {
    'country': 'Togo',
    'abbr': 'TG'
  },
  {
    'country': 'Thailand',
    'abbr': 'TH'
  },
  {
    'country': 'Tajikistan',
    'abbr': 'TJ'
  },
  {
    'country': 'Tokelau',
    'abbr': 'TK'
  },
  {
    'country': 'Turkmenistan',
    'abbr': 'TM'
  },
  {
    'country': 'East Timor',
    'abbr': 'TP'
  },
  {
    'country': 'Tonga',
    'abbr': 'TO'
  },
  {
    'country': 'Trinidad and Tobago',
    'abbr': 'TT'
  },
  {
    'country': 'Tunisia',
    'abbr': 'TN'
  },
  {
    'country': 'Turkey',
    'abbr': 'TR'
  },
  {
    'country': 'Tuvalu',
    'abbr': 'TV'
  },
  {
    'country': 'Taiwan',
    'abbr': 'TW'
  },
  {
    'country': 'Tanzania',
    'abbr': 'TZ'
  },
  {
    'country': 'Uganda',
    'abbr': 'UG'
  },
  {
    'country': 'Ukraine',
    'abbr': 'UA'
  },
  {
    'country': 'United States Minor Outlying Islands',
    'abbr': 'UM'
  },
  {
    'country': 'Uruguay',
    'abbr': 'UY'
  },
  {
    'country': 'United States',
    'abbr': 'US'
  },
  {
    'country': 'Uzbekistan',
    'abbr': 'UZ'
  },
  {
    'country': 'Holy See (Vatican City State)',
    'abbr': 'VA'
  },
  {
    'country': 'Saint Vincent and the Grenadines',
    'abbr': 'VC'
  },
  {
    'country': 'Venezuela',
    'abbr': 'VE'
  },
  {
    'country': 'British Virgin Islands',
    'abbr': 'VG'
  },
  {
    'country': 'United States Virgin Islands',
    'abbr': 'VI'
  },
  {
    'country': 'Vietnam',
    'abbr': 'VN'
  },
  {
    'country': 'Vanuatu',
    'abbr': 'VU'
  },
  {
    'country': 'Wallis and Futuna',
    'abbr': 'WF'
  },
  {
    'country': 'Samoa',
    'abbr': 'WS'
  },
  {
    'country': 'Yemen',
    'abbr': 'YE'
  },
  {
    'country': 'Yugoslavia',
    'abbr': 'YU'
  },
  {
    'country': 'South Africa',
    'abbr': 'ZA'
  },
  {
    'country': 'Zambia',
    'abbr': 'ZM'
  },
  {
    'country': 'Zimbabwe',
    'abbr': 'ZW'
  }
];
