<template>
     <div id="pageTableSaida">
        <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex sm12>
                <h3>Saídas do almoxarifado</h3>
                </v-flex>
                <v-flex lg12>
                    <v-card>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <relatorio-modal></relatorio-modal>
                            <saida-modal></saida-modal>
                        </v-toolbar>
                        <v-toolbar card color="white">
                        <v-text-field
                        flat
                        solo
                        prepend-icon="search"
                        placeholder="Digite algo"
                        v-model="search"
                        hide-details
                        class=""
                        ></v-text-field>     
                        <v-btn icon>
                            <v-icon>filter_list</v-icon>
                        </v-btn>         
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                        <v-data-table
                            :headers="complex.headers"
                            :search="search"
                            :items="complex.items"
                            :rows-per-page-items="[10,25,50,{text:'Todos','value':-1}]"
                            rows-per-page-text="Linhas por página"
                            class="elevation-1"
                            item-key="name"
                            v-model="complex.selected"
                            >
                            <template slot="items" slot-scope="props">            
                            <td>{{ props.item.id }}</td>
                            <td>{{ props.item.funcionario.NmFuncionario }}</td>
                            <td>{{ props.item.item.descricao }}</td>
                            <td align="center">{{ props.item.quantidade | formatNumber}}</td>
                            <td align="center">{{ props.item.valorUltEntrada | formatNumber}}</td>
                            <td align="center">{{ props.item.TipoRequisicao }}</td>
                            <td align="center">{{ props.item.dtEntrega | formatDate('DD/MM/YYYY')}}</td>
                            <td>
                                <v-btn depressed outline icon fab dark color="primary" small @click.native="editar(props.item)">
                                    <v-icon>find_in_page</v-icon>
                                </v-btn>
                                <v-btn depressed outline icon fab dark color="pink" small @click.native="excluir(props.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </td>
                            </template>
                        </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>       
        </v-container>
    </div>
</template>

<script>
import toastr from 'toastr'
import SaidaModal from '@/components/almoxarifado/Saida.vue'
import RelatorioModal from '@/components/almoxarifado/RelatorioSaidasCategoriaFuncionario.vue'
 import * as moment from "moment";
// import 'moment/locale/pt-br'
// moment.locale('pt-BR')

export default {
name: 'Saidas',
  components:{
    SaidaModal,
    RelatorioModal
  },
  data () {
    return {
      dialog: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Funcionário',
            value: 'funcionario.NmFuncionario'
          },
          {
            text: 'Item',
            value: 'item.descricao'
          },
          {
            text: 'Quantidade',
            value: 'quantidade'
          },
          {
            text: 'Valor Ult. Entrada',
            value: 'valorUltEntrada'
          },
          {
            text: 'Tipo',
            value: 'TipoRequisicao'
          },
          {
            text: 'Data da Entrega',
            value: 'dtEntrada'
          },
          {
            text: 'Ações',
            value: ''
          }
        ],
        items: []
      },
    }
  },
   methods: {
       getList () {
           this.complex.items = []
           toastr.success('Atualizando saídas do almoxarifado...','Varejão da Sucata')
            this.$http.get(this.$store.state.urlBase+'almoxarifado/saida')
                        .then(response => {
                            this.complex.items = response.data
                        })
       },
       editar (item) {
        // console.log(item)
        this.$store.commit('abrirModalSaida',item)
        // console.log(this.$store.state.dialogEntrada)
       },
       excluir (item) {
          var http = this.$http
          var url = this.$store.state.urlBase
          toastr.warning("<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",'Deseja realmente excluir esta saída?',
           {
               allowHtml: true,
               onclick: function (toast) {
                 var value = toast.target.value

                 if (value == 'Sim') {
                   http.delete(url+'almoxarifado/saida/'+item.id)
                   .then(response => {
                     console.log(response.data.message)
                     toastr.success(response.data.message,'Varejão da Sucata')
                     window.getApp.$emit('APP_SAIDA_UPDATED')
                   })  
                 }
                 toastr.remove()
                 toastr.clear()
               }

           })
       }
   },
  created () {
    this.getList ()
    window.getApp.$emit('APP_FUNCIONARIOS_GET');
    window.getApp.$emit('APP_ITENS_GET');
    window.getApp.$emit('APP_UNIDADES_GET');
    delete window.getApp._events.APP_SAIDA_UPDATED
    delete window.getApp._events.APP_RECORD_UPDATED
    window.getApp.$on('APP_SAIDA_UPDATED',this.getList)
    window.getApp.$on('APP_RECORD_UPDATED',this.getList)
  },
  updated () {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value){
          let number = parseFloat(value)
          return number.toFixed(2)
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  }
}
</script>