<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-btn
      slot="activator"
      color="primary"
      dark
      small
      outline
      depressed
      icon
      fab
      @click.native="buscarParams()"
    >
      <v-icon>find_in_page</v-icon>
    </v-btn>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Parametrizações de Fatura</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark flat @click="salvar()">Salvar</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :disabled="true"
                label="Nome Completo * "
                v-model="item.NmFornec"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :disabled="true"
                label="CPF/CPNJ *"
                v-model="item.CdCpfCgcFormatado"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-autocomplete
                :items="$store.state.metodoPagamento"
                item-text="descricao"
                item-value="valor"
                label="Forma de pagamento *"
                v-model="param.CdFormaPagto"
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                label="Quantidade de dias *"
                v-model="param.quantidadeDias"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <!-- <small>* campo obrigatório</small> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
        <v-btn color="blue darken-1" flat @click.native="salvar()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
export default {
  props: {
    item: {},
  },
  data() {
    return {
      param: {},
      dialog: false,
      valor: 16,
    };
  },
  components: {},
  computed: {},
  methods: {
    salvar() {
      toastr.success("Salvando parâmetros para faturas...", "Varejão da Sucata");
      console.log(this.param);
      let param = this.param;
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .put(
          this.$store.state.urlBase + "v1/financeiro/paramFatura/" + this.item.CdFornec,
          param
        )
        .then((response) => {
          if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "Parametros para Faturas");
            }
          } else {
            console.log(response);
            this.atualizar();
          }
        });
      this.dialog = false;
    },
    atualizar() {
      this.param = {};
      this.dialog = false;
    },
    buscarParams() {
      toastr.success("Buscando parâmetros para faturas...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(
          this.$store.state.urlBase + "v1/financeiro/paramFatura/" + this.item.CdFornec
        )
        .then((response) => {
          if (response.data) {
            this.param = response.data;
          } else {
            this.param = {
              CdFormaPagto: 0,
              quantidadeDias: 0,
            };
          }
        });
    },
  },
  filters: {},
  mounted() {},
  updated() {
    // console.log(this.item);
  },
};
</script>
