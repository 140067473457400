<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Permissões por setor</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <permissao></permissao>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10,25,50,{text:'Todos','value':-1}]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
              >
                <template slot="items" slot-scope="props" @click.native="editar(props.item)">
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.setor.NmSetor }}</td>
                    <td>{{ props.item.path }}</td>
                    <td>{{ props.item.group}}</td>
                    <td>
                      <v-btn depressed outline icon fab dark color="primary" small @click.native="editar(props.item)">
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <v-btn depressed outline icon fab dark color="pink" small @click.native="excluir(props.item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import toastr from "toastr";
import Permissao from '@/components/usuario/Permissao.vue'
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

export default {
  name: "Permissoes",
  components: {
    Permissao
  },
  data() {
    return {
      dialog: false,
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Setor",
            value: "setor.NmSetor",
          },
          {
            text: "Path",
            value: "path",
          },
          {
            text: "Group",
            value: "group",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getList() {
      this.complex.items = [];
      toastr.success(
        "Atualizando Lista de Permissões...",
        "Varejão da Sucata"
      );
      this.$http
        .get(this.$store.state.urlBase + "v1/rh/permissoes")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
      window.getApp.$emit('APP_SETORES_GET')
    },
    editar(item) {
      this.$store.commit('abrirModalPermissoes',item)
    },
    excluir (item) {
          var http = this.$http
          var url = this.$store.state.urlBase
          toastr.warning("<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",'Deseja realmente excluir esta permissão?',
           {
               allowHtml: true,
               onclick: function (toast) {
                 var value = toast.target.value

                 if (value == 'Sim') {
                   http.delete(url+'v1/rh/permissoes/'+item.id)
                   .then(response => {
                     console.log(response.data.message)
                     toastr.success(response.data.message,'Varejão da Sucata')
                     window.getApp.$emit('APP_PERMISSOES_SUCATA_UPDATED')
                   })  
                 }
                 toastr.remove()
                 toastr.clear()
               }

           })
       }
  },
  created() {
    this.getList()
    
    // window.getApp.$emit('APP_COMPRADORES_GET')
    // console.log(window.getApp._events)
    delete window.getApp._events.APP_PERMISSOES_SUCATA_UPDATED
    delete window.getApp._events.APP_RECORD_UPDATED
    window.getApp.$on('APP_PERMISSOES_SUCATA_UPDATED',this.getList)
    window.getApp.$on('APP_RECORD_UPDATED',this.getList)
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt)
    }
  },
};
</script>