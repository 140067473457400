<template>
    <v-dialog v-model="$store.state.dialogEntrada" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Nova Entrada Avulsa
        </v-btn>
        <v-form>
            <v-card>
                <v-card-title>
                    <span class="headline">Entrada Avulsa</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                                <v-autocomplete
                                    :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                    v-model="$store.state.entrada.CdFornec"
                                    :items="$store.getters.fornecedores"
                                    item-text="NmFornec"
                                    item-value="CdFornec"
                                    label="Fornecedor *"
                                    required

                                    ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                                <v-autocomplete
                                    :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                    v-model="$store.state.entrada.item_id"
                                    :items="$store.getters.itens"
                                    item-text="descricao"
                                    item-value="id"
                                    label="Item *"
                                    required
                                    ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                                <v-text-field 
                                :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                label="Aplicação *" v-model="$store.state.entrada.aplicacao"></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-dialog
                                    :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                    ref="dialogDataEntrada"
                                    v-model="modalDataEntrada"
                                    :return-value.sync="$store.state.entrada.dtEntrada"
                                    persistent
                                    lazy
                                    full-width
                                    width="290px"
                                >
                                    <v-text-field
                                        :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                        slot="activator"
                                        v-model="dtEntradaFormatted"
                                        label="Data de Entrada"
                                        prepend-icon="event"
                                        readonly
                                        ></v-text-field>
                                    <v-date-picker 
                                        v-model="$store.state.entrada.dtEntrada" 
                                        locale="pt-br"
                                        scrollable 
                                        @change="$refs.dialogDataEntrada.save($store.state.entrada.dtEntrada)"
                                        @input="dtEntradaFormatted=formatDate($store.state.entrada.dtEntrada) " >
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="primary" @click="modalDataEntrada = false">Cancelar</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-dialog
                                    ref="dialogDataValidade"
                                    v-model="modalDataValidade"
                                    :return-value.sync="$store.state.entrada.dtValidade"
                                    persistent
                                    lazy
                                    full-width
                                    width="290px"
                                >
                                    <v-text-field
                                    :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                    slot="activator"
                                    v-model="dtValidadeFormatted"
                                    label="Data de Validade"
                                    prepend-icon="event"
                                    readonly
                                    ></v-text-field>
                                    <v-date-picker 
                                        v-model="$store.state.entrada.dtValidade"
                                        locale="pt-br"
                                        @change="$refs.dialogDataValidade.save($store.state.entrada.dtValidade)"
                                        scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="modalDataValidade = false">Cancelar</v-btn>
                                    <v-btn flat color="primary" @click="$refs.dialogDataValidade.save($store.state.entrada.dtValidade)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-text-field 
                                :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                label="Quantidade *" required v-model="$store.state.entrada.quantidade" ></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-text-field 
                                :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                label="Valor unitário *"  v-model="$store.state.entrada.valorUnitario"></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-select
                                    :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                    v-model="$store.state.entrada.CdUnidade"
                                    :items="$store.state.unidades"
                                    :rules="[]"
                                    label="Unidade *"
                                    required
                                    ></v-select>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-text-field :disabled="typeof($store.state.entrada.id) !== 'undefined'" label="Lote" v-model="$store.state.entrada.lote"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <small>* campo obrigatório</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click.native="atualizar()">Cancelar</v-btn>
                    <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.getters.entrada.id">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
export default {
    data () {
        return {
            modalDataEntrada:false,
            modalDataValidade:false,
            dialog: false,
            valor: 16
        }
    },
    computed: {
        dtEntradaFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.entrada.dtEntrada) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.entrada.dtEntrada).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.entrada.dtEntrada = this.parseDate(date)
                return date
            }
        },
        dtValidadeFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.entrada.dtValidade) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.entrada.dtValidade).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.entrada.dtValidade = this.parseDate(date)
                return date
            }     
        }
    },
    methods : {
        salvar (){
            let entrada = this.$store.state.entrada
            this.$http.post(this.$store.state.urlBase+'almoxarifado/entrada',entrada)
                .then(response => {
                   if(typeof response.data.errors !== 'undefined'){
                        //Erros de Validação
                        for (var error in response.data.errors) {
                            toastr.error(response.data.errors[error],'Entrada de Itens')
                        }
                   }else{
                       this.atualizar()
                   }
                   
            })
        },
        atualizar () {
            this.$store.state.entrada = {}
            window.getApp.$emit('APP_ENTRADA_UPDATED')
            this.$store.commit('fecharModalEntrada')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toFixed(2)
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
        }
  },
    mounted () {
        
    }
}
</script>
