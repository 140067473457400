<template>
    <v-dialog v-model="$store.state.dialogItem" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Item
        </v-btn>
        <v-card>
            <v-card-title>
                <span class="headline">Item</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Descrição do Item *" required v-model="$store.state.item.descricao"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Descrição Complementar do Item *" required v-model="$store.state.item.descricaoComplementar"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Aplicação *" v-model="$store.state.item.aplicacao"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Referência" v-model="$store.state.item.referencia"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field label="Quantidade Atual *" required v-model="$store.state.item.quantidadeAtual" v-show="!$store.state.item.id"></v-text-field>
                            <v-text-field disabled label="Quantidade Atual *" required v-model="$store.state.item.quantidadeAtual" v-show="$store.state.item.id"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field label= "Quantidade Minima *" required v-model="$store.state.item.quantidadeMinima"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field label="Valor da Última Entrada *"  v-model="$store.state.item.valorUltEntrada"  v-show="!$store.state.item.id"></v-text-field>
                            <v-text-field disabled label="Valor da Última Entrada *"  v-model="$store.state.item.valorUltEntrada"  v-show="$store.state.item.id"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-select
                                v-model="$store.state.item.CdUnidade"
                                :items="$store.state.unidades"
                                :rules="[]"
                                label="Unidade *"
                                required
                                v-show="!$store.state.item.id"
                                ></v-select>
                            <v-text-field disabled label= "Unidade *" required v-model="$store.state.item.CdUnidade" v-show="$store.state.item.id"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-select
                                v-model="$store.state.item.categoria_id"
                                :items="$store.state.categorias"
                                label="Categoria *"
                                required
                                item-text="descricao"
                                item-value="id"
                                ></v-select>
                            <!-- <v-text-field label= "Categoria *" required v-model="valor"></v-text-field> -->
                        </v-flex>
                    </v-layout>
                </v-container>
                <small>* campo obrigatório</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Cancelar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.state.item.id">Salvar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="update()" v-else>Atualizar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
export default {
    data () {
        return {
            dialog: false,
            valor: 1
        }
    },
    methods : {
        salvar (){
            let item = this.$store.state.item
            this.$http.post(this.$store.state.urlBase+'almoxarifado/item',item)
                .then(response => {
                   if(typeof response.data.errors !== 'undefined'){
                        //Erros de Validação
                        for (var error in response.data.errors) {
                            toastr.error(response.data.errors[error],'Cadastro de Itens')
                        }
                   }else{
                       this.atualizar()
                   }
                   
            })
        },
        update (){
            let valor= 1
            let item = this.$store.state.item
            this.$http.put(this.$store.state.urlBase+'almoxarifado/item',item)
                .then(response => {
                   if(typeof response.data.errors !== 'undefined'){
                        //Erros de Validação
                        for (var error in response.data.errors) {
                            toastr.error(response.data.errors[error],'Cadastro de Itens')
                        }
                   }else{
                       this.atualizar()
                   }
            })
        },
        atualizar () {
            this.$store.state.item = {}
            window.getApp.$emit('APP_ITEM_UPDATED')
            this.$store.state.dialogItem = false
        }
    },
    filters: {
      formatNumber(value){
          let number = parseFloat(value)
          return number.toFixed(2)
      },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    }
  },
    mounted () {
        
    }
}
</script>
