<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Notas Fiscais Destinadas</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <!-- <v-btn color="primary" @click.native="mostrar()">Mostrar</v-btn> -->
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="chNFe"
                :pagination.sync="complex.pagination"
                v-model="complex.selected"
                select-all
              >
                <template v-slot:headers="props">
                  <tr>
                    <th>
                      <v-checkbox
                        :input-value="props.all"
                        :indeterminate="props.indeterminate"
                        primary
                        hide-details
                        @click.stop="toggleAll(props)"
                      ></v-checkbox>
                    </th>
                    <th
                      v-for="header in props.headers"
                      :key="header.text"
                      :class="[
                        'column sortable',
                        'text-xs-left',
                        complex.pagination.descending ? 'desc' : 'asc',
                        header.value === complex.pagination.sortBy ? 'active' : '',
                      ]"
                      @click="changeSort(header.value)"
                    >
                      {{ header.text }}
                      <v-icon small>arrow_upward</v-icon>
                    </th>
                  </tr>
                </template>
                <template
                  v-slot:items="props"
                  :options="complex.options"
                  @click.native="editar(props.item)"
                >
                  <tr :active="props.selected" @click="props.selected = !props.selected">
                    <td>
                      <v-checkbox
                        v-model="props.selected"
                        primary
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td>{{ props.item.NSU }}</td>
                    <td width="10%">{{ props.item.chNFe }}</td>
                    <td>{{ props.item.xNome }} - {{ props.item.cnpjFormated }}</td>
                    <td>
                      {{ props.item.dhEmi | formatDate("DD/MM/Y hh:mm:ss") }}
                    </td>
                    <td>{{ props.item.NrNf }}</td>
                    <td>{{ props.item.NmSerieNf }}</td>
                    <!-- <td>{{ props.item.VlNf | formatNumber }}</td> -->
                    <!-- <td>{{ props.item.NmTransportadora }}</td> -->
                    <td>
                      <!-- Retorno -->
                      <v-tooltip top v-if="props.item.escriturada == '1'">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on">check_circle</v-icon>
                        </template>
                        <span>Escriturado</span>
                      </v-tooltip>

                      <v-tooltip top v-if="props.item.cSitConf == '4'">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on">sports_score</v-icon>
                        </template>
                        <span>Ciência da Operação</span>
                      </v-tooltip>

                      <v-tooltip top v-if="props.item.cSitConf == '1'">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on">cloud_done</v-icon>
                        </template>
                        <span>Confirmação da Operação</span>
                      </v-tooltip>
                      <v-tooltip top v-if="props.item.cSitConf == '2'">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on">search_off</v-icon>
                        </template>
                        <span>Desconhecimento da Operação</span>
                      </v-tooltip>
                      <v-tooltip top v-if="props.item.cSitConf == '3'">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on">cloud_off</v-icon>
                        </template>
                        <span>Operação não realizada</span>
                      </v-tooltip>

                      <v-tooltip top v-if="props.item.escriturada == '0'">
                        <template v-slot:activator="{ on }">
                          <v-icon color="red" v-on="on">cancel</v-icon>
                        </template>
                        <span>Não Escriturado</span>
                      </v-tooltip>
                      <!-- <span>{{ props.item.cSitCTe }}</span> -->
                      <br />
                      <span v-if="props.item.cSitNFe == '3'">NFe Cancelada</span>
                      <!-- {{ props.item.escriturada }} -->
                    </td>

                    <td>
                      <!-- Menu -->
                      <div class="text-xs-center">
                        <v-menu
                          v-model="props.item.menuNFe"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          offset-x
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark v-on="on" fab small flat
                              ><v-icon dark>list</v-icon></v-btn
                            >
                          </template>

                          <v-card>
                            <v-list>
                              <v-subheader>Funções NF-e</v-subheader>

                              <v-list-tile>
                                <v-list-tile-action>
                                  <danfe-view
                                    :item="props.item"
                                    textoBotao="Ver NFe"
                                    :usarChave="true"
                                  ></danfe-view>
                                </v-list-tile-action>
                                <v-list-tile-title>Ver NFe</v-list-tile-title>
                              </v-list-tile>

                              <v-list-tile :disabled="props.item.escriturada == '1'">
                                <v-list-tile-action>
                                  <upload-xml
                                    :chNFe="props.item.chNFe"
                                    :key="props.item.chNFe + '_xml'"
                                    :escriturado="props.item.escriturada == '1'"
                                  ></upload-xml>
                                </v-list-tile-action>
                                <v-list-tile-title>Upload XML</v-list-tile-title>
                              </v-list-tile>

                              <v-list-tile>
                                <v-list-tile-action>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        dark
                                        outline
                                        icon
                                        fab
                                        small
                                        color="primary"
                                        @click.native="downloadXML(props.item)"
                                        v-on="on"
                                      >
                                        <v-icon>archive</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Download XML</span>
                                  </v-tooltip>
                                </v-list-tile-action>
                                <v-list-tile-title>Download XML</v-list-tile-title>
                              </v-list-tile>

                              <v-list-tile>
                                <v-list-tile-action>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        dark
                                        outline
                                        icon
                                        fab
                                        small
                                        color="primary"
                                        @click.native="consultarSefaz(props.item)"
                                        v-on="on"
                                      >
                                        <v-icon>travel_explore</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Consultar Sefaz</span>
                                  </v-tooltip>
                                </v-list-tile-action>
                                <v-list-tile-title>Consultar Sefaz</v-list-tile-title>
                              </v-list-tile>

                              <v-list-tile :disabled="props.item.escriturada == '1'">
                                <v-list-tile-action>
                                  <escriturar-nfe
                                    :item="props.item"
                                    textoBotao="Escriturar NFe"
                                    :usarChave="true"
                                  ></escriturar-nfe>
                                </v-list-tile-action>
                                <v-list-tile-title>Escriturar NFe</v-list-tile-title>
                              </v-list-tile>

                              <v-list-tile :disabled="props.item.escriturada != '1'">
                                <v-list-tile-action>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        dark
                                        outline
                                        icon
                                        fab
                                        small
                                        color="red"
                                        v-on="on"
                                      >
                                        <v-icon>delete_forever</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Excluir Escrituração</span>
                                  </v-tooltip>
                                </v-list-tile-action>
                                <v-list-tile-title
                                  >Excluir Escrituração</v-list-tile-title
                                >
                              </v-list-tile>

                              <v-subheader>Manifestação Destinatário</v-subheader>
                              <v-list-tile
                                v-for="op in filteredTipoManifestacao(
                                  props.item.cSitConf
                                )"
                                :key="op.tipo + '_manifestacao'"
                              >
                                <v-list-tile-action>
                                  <manifestacao
                                    :tipo="op.tipo"
                                    :chNFe="props.item.chNFe"
                                  ></manifestacao>
                                </v-list-tile-action>
                                <v-list-tile-title>{{
                                  tipos[op.tipo].description
                                }}</v-list-tile-title>
                              </v-list-tile>
                            </v-list>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn
                                flat
                                @click="props.item.menuNFe = false"
                                small
                                color="primary"
                                >Fechar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </div>
                      <!-- End Menu -->
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogCiencia" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Manifestação de NF'e (Ciência)</v-card-title>
        <v-card-text>Deseja manifestar a ciência da operação?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="dialogCiencia = false">Não</v-btn>
          <v-btn color="primary darken-1" flat @click="ciencia()">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProcessando" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          Aguarde o Download do XML
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import toastr from "toastr";
import * as moment from "moment";
import DacteView from "@/pages/comercial/DacteView.vue";
import DanfeView from "@/pages/contabilidade/DanfeView.vue";
import EscriturarNfe from "@/pages/contabilidade/EscriturarNFe.vue";
import Frete from "@/components/contabilidade/Frete.vue";
import Manifestacao from "@/components/contabilidade/Manifestacao.vue";
import UploadXml from "@/components/contabilidade/UploadXML.vue";
import "moment/locale/pt-br";
// import UploadXML from '../../components/contabilidade/UploadXML.vue';

moment.locale("pt-BR");

export default {
  name: "NfesDestinadas",
  components: {
    DacteView,
    DanfeView,
    Frete,
    Manifestacao,
    UploadXml,
    EscriturarNfe,
  },
  data() {
    return {
      menuNFe: false,
      dialog: false,
      dialogCiencia: false,
      dialogProcessando: false,
      search: "",
      file: {},
      bin: {},
      exibirPDF: false,
      item: {},
      selected: [],
      manifestacoes: [],
      complex: {
        selected: [],
        pagination: {
          sortBy: "dhEmi",
          descending: true,
        },
        headers: [
          {
            text: "NSU",
            value: "NSU",
          },
          {
            text: "Chave",
            value: "chNFe",
          },
          {
            text: "Fornecedor",
            value: "xNome",
          },
          {
            text: "Data de Emissão",
            value: "dhEmi",
          },
          {
            text: "Número",
            value: "NrNf",
          },
          {
            text: "Série",
            value: "NmSerieNf",
          },
          {
            text: "Status",
            value: "CdRetorno",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
      tipos: [
        {
          description: "Sem Manifestação do Destinatário",
          shortDescription: null,
          service: null,
          tipo: 0,
          icon: "",
        },
        {
          description: "Confirmação da Operação",
          shortDescription: "Confirmação",
          service: "confirmacao",
          icon: "thumb_up_alt",
          tipo: 1,
        },
        {
          description: "Desconhecimento da Operação",
          shortDescription: "Desconhecimento",
          service: "desconhecimento",
          icon: "thumb_down_alt",
          tipo: 2,
        },
        {
          description: "Operação não realizada",
          shortDescription: "Não realização",
          service: "naorealizada",
          icon: "block",
          tipo: 3,
        },
        {
          description: "Ciência da Operação",
          shortDescription: "Ciência",
          service: "ciencia",
          icon: "sports_score",
          tipo: 4,
        },
      ],
    };
  },
  methods: {
    ciencia() {
      // console.log(item);
      toastr.success("Manifestando ciência da operação...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(this.$store.state.urlBase + "v1/sefaz/ciencia/" + this.item.chNFe)
        .then((response) => {
          if (response.data.erro) {
            toastr.error(response.data.erro, "Nota Fiscal Destinada");
            //Perguntando se quer confirmar a ciencia
            this.dialogCiencia = true;
          } else {
            toastr.success(
              "Ciência manifestada! Aguarde pelo menos 1 minuto fazer o download do XML.",
              "Varejão da Sucata"
            );
            this.getList();
          }
        });
      this.dialogCiencia = false;
    },
    consultarSefaz(item) {
      let url =
        "http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&tipoConteudo=XbSeqxE8pl8=&nfe=" +
        item.chNFe;
      window.open(url);
    },
    downloadXML(item) {
      this.item = item;
      // toastr.success("Buscando XML...", "Varejão da Sucata");
      this.dialogProcessando = true;
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(this.$store.state.urlBase + "v1/sefaz/downloadxml/" + item.chNFe)
        .then((response) => {
          this.dialogProcessando = false;
          if (response.data.erro) {
            toastr.error(response.data.erro, "Nota Fiscal Destinada");
            //Perguntando se quer confirmar a ciencia
            this.dialogCiencia = true;
          } else {
            item.xml = response.data.xml;
            this.forceDownload(item);
          }
        });
    },
    forceDownload(item) {
      var pseudoelement = document.createElement("a");

      var filename = item.chNFe + ".xml";
      var pseudoelement = document.createElement("a");
      var blob = new Blob([item.xml], { type: "text/plain" });

      pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
      pseudoelement.setAttribute("download", filename);

      pseudoelement.dataset.downloadurl = [
        "text/plain",
        pseudoelement.download,
        pseudoelement.href,
      ].join(":");
      pseudoelement.draggable = true;
      pseudoelement.classList.add("dragout");

      pseudoelement.click();
    },
    getList() {
      //this.complex.items = [];
      toastr.success("Atualizando Lista de NFes...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(this.$store.state.urlBase + "v1/contabilidade/destinadas/nfe")
        .then((response) => {
          this.complex.items = [];
          setTimeout(function () {
            toastr.success("Lista de NFes atualizada...", "Varejão da Sucata");
          }, 1000);
          this.complex.items = Object.values(response.data);
        });
    },
    filteredTipoManifestacao(cSitConf) {
      return this.manifestacoes.filter((item) => {
        return item.tipo > 0 && (item.tipo < cSitConf || cSitConf == null);
      });
    },
    editar(item) {
      // this.$store.commit("abrirModalMTR", item);
    },
    verDanfe(item) {
      // toastr.success("Gerando a visualização do DANFE", "Varejão da Sucata");
      // window.getApp.$emit("APP_GET_DANFE", item.NrNf);
      // this.$store.commit("abrirModalDanfe", item);
    },
    verMtr(item) {
      // toastr.success("Gerando a visualização do MTR", "Varejão da Sucata");
      // window.getApp.$emit("APP_GET_DANFE", item.NrNf);
      // this.$store.commit("abrirModalViewMTR", item);
    },
    excluir(item) {},

    mostrar() {
      Manifestacao.methods.abrirManifestacao("ciencia");
      Manifestacao.data().dialog = true;
      console.log(Manifestacao);
    },
    toggleAll(props) {
      console.log(props);
      if (this.complex.selected.length) this.complex.selected = [];
      else this.complex.selected = this.complex.items.slice();
    },
    changeSort(column) {
      if (this.complex.pagination.sortBy === column) {
        this.complex.pagination.descending = !this.complex.pagination.descending;
      } else {
        this.complex.pagination.sortBy = column;
        this.complex.pagination.descending = false;
      }
    },
  },
  created() {
    this.getList();
    this.manifestacoes = Manifestacao.data().tipos;
    // console.log(this.manifestacoes);
    // delete window.getApp._events.APP_PRECOS_SUCATA_UPDATED;
    delete window.getApp._events.APP_RECORD_UPDATED;

    if (!this.$store.state.loja.CdUf) {
      window.getApp.$emit("APP_LOJA_GET");
    }

    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    tipoManifestacao(op) {
      console.log(op);
      // return (op.tipo > 0) &&
      //                   (op.tipo < props.item.cSitConf || props.item.cSitConf == null)
    },
    formatNumber(value) {
      let number = parseFloat(value);
      let valor = number.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
      return valor;
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>
