<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          outline
          icon
          fab
          dark
          color="primary"
          small
          v-on="on"
          @click.native="buscarParams()"
        >
          <v-icon>how_to_vote</v-icon>
        </v-btn>
      </template>
      <span>Escriturar CTE</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Escriturar CTe</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn dark outline icon fab small @click="downloadXML()" v-on="on">
              <v-icon>archive</v-icon>
            </v-btn>
          </template>
          <span>Download XML</span>
        </v-tooltip>
        <v-toolbar-items v-if="item.fornecedor">
          <v-btn dark flat @click="editar()"
            ><span v-if="!abrirEdicao">Fechar Edição</span
            ><span v-if="abrirEdicao">Abrir Edição</span></v-btn
          >
          <v-btn dark flat @click="salvar()">Escriturar</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :disabled="abrirEdicao"
                label="Fornecedor"
                v-model="nmFornec"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md1>
              <v-text-field
                label="Espécie *"
                v-model="escritura.especie"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md1>
              <v-autocomplete
                :items="cfops"
                :disabled="abrirEdicao"
                item-text="CdCfo"
                item-value="CdCfo"
                label="CFOP *"
                v-model="escritura.cFOP"
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 md1>
              <v-text-field
                label="Série *"
                v-model="escritura.NmSerieNf"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-text-field
                label="Nº CTE *"
                v-model="escritura.NrNf"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-dialog
                :disabled="false"
                ref="dialogDataEmissao"
                v-model="modalDataEmissao"
                :return-value.sync="escritura.dtEmissao"
                persistent
                lazy
                full-width
                width="290px"
              >
                <v-text-field
                  :disabled="abrirEdicao"
                  slot="activator"
                  v-model="dtEmissaoFormatted"
                  label="Data de Emissão "
                  prepend-icon="event"
                  readonly
                  required
                ></v-text-field>
                <v-date-picker
                  v-model="escritura.dtEmissao"
                  :min="item.arquivo.DtEmissao"
                  locale="pt-br"
                  scrollable
                  @change="$refs.dialogDataEmissao.save(escritura.dtEmissao)"
                  @input="dtEmissaoFormatted = formatDate(escritura.dtEmissao)"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalDataEmissao = false"
                    >Cancelar</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-dialog
                :disabled="false"
                ref="dialogDataEntrada"
                v-model="modalDataEntrada"
                :return-value.sync="escritura.dtEntrada"
                persistent
                lazy
                full-width
                width="290px"
              >
                <v-text-field
                  :disabled="abrirEdicao"
                  slot="activator"
                  v-model="dtEntradaFormatted"
                  label="Data de Entrada *"
                  prepend-icon="event"
                  readonly
                  required
                ></v-text-field>
                <v-date-picker
                  v-model="escritura.dtEntrada"
                  :min="item.arquivo.dtEmissao"
                  locale="pt-br"
                  scrollable
                  @change="$refs.dialogDataEntrada.save(escritura.dtEntrada)"
                  @input="dtEntradaFormatted = formatDate(escritura.dtEntrada)"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalDataEntrada = false"
                    >Cancelar</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-autocomplete
                :items="tipo"
                :disabled="abrirEdicao"
                item-text="text"
                item-value="value"
                label="Tipo *"
                v-model="escritura.tipo"
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Observação"
                v-model="escritura.observacao"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Chave de Acesso"
                v-model="escritura.chCTe"
                :disabled="abrirEdicao"
              ></v-text-field>
            </v-flex>
            <v-card elevation="1" style="width: 100%">
              <v-card-title>ICMS</v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md3>
                      <v-text-field
                        label="Base ICMS"
                        v-model="baseICMS"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-text-field
                        label="ICMS"
                        v-model="pICMS"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-text-field
                        label="Valor ICMS"
                        v-model="icms"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-text-field
                        label="Valor Total"
                        v-model="valorTotal"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>

            <v-spacer></v-spacer>
            <v-card elevation="1" style="width: 100%" class="mt-3">
              <v-card-title>Fatura</v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.eventos"
                        :disabled="abrirEdicao"
                        item-text="DsEvento"
                        item-value="CdEvento"
                        label="Evento *"
                        v-model="escritura.evento"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.ccustos"
                        :disabled="abrirEdicao"
                        item-text="NmCCusto"
                        item-value="CdCCusto"
                        label="Centro de Custo *"
                        v-model="escritura.ccusto"
                        required
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        label="Parcela"
                        v-model="escritura.parcela"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        label="Qtd. Dias"
                        v-model="escritura.quantidadeDias"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-dialog
                        :disabled="false"
                        ref="dialogDataVencimento"
                        v-model="modalDataVencimento"
                        :return-value.sync="escritura.dtVencimento"
                        persistent
                        lazy
                        full-width
                        width="290px"
                      >
                        <v-text-field
                          :disabled="abrirEdicao"
                          slot="activator"
                          v-model="dtVencimentoFormatted"
                          label="Vencimento *"
                          prepend-icon="event"
                          readonly
                          required
                        ></v-text-field>
                        <v-date-picker
                          v-model="escritura.vencimento"
                          :min="item.arquivo.dtEmissao"
                          locale="pt-br"
                          scrollable
                          @change="$refs.dialogDataVencimento.save(escritura.vencimento)"
                          @input="
                            dtVencimentoFormatted = formatDate(escritura.vencimento)
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="modalDataEntrada = false"
                            >Cancelar</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        label="Valor da Parcela"
                        v-model="valorParcela"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        label="Percentual"
                        v-model="escritura.percentual"
                        :disabled="abrirEdicao"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        :items="$store.state.metodoPagamento"
                        item-text="descricao"
                        :disabled="abrirEdicao"
                        item-value="valor"
                        label="Forma de pagamento *"
                        v-model="escritura.CdFormaPagto"
                        required
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-container>
        <small>* campo obrigatório</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
        <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="item.fornecedor"
          >Escriturar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import { format, unformat } from "@/components/VMoeda/utils";
export default {
  props: {
    item: {},
  },
  data() {
    return {
      param: {},
      dialog: false,
      valor: 16,
      trans: "FP022NfE",
      cfops: [],
      cte: {},
      abrirEdicao: true,
      escritura: {},
      dtEmissao: new Date().toISOString().substr(0, 10),
      dtEntrada: new Date().toISOString().substr(0, 10),
      dtVencimento: new Date().toISOString().substr(0, 10),
      modalDataEmissao: false,
      modalDataVencimento: false,
      modalDataEntrada: false,
      modalDataValidade: false,
      tipo: [
        { text: "Administrativa", value: "A" },
        { text: "S/Vendas", value: "V" },
        { text: "Importação ", value: "I" },
        { text: "Produto Acabado ", value: "P" },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      percent: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  components: {},
  computed: {
    cFOP() {
      if (
        this.item.fornecedor &&
        this.$store.state.loja.CdUf &&
        this.item.fornecedor.CdUf == this.$store.state.loja.CdUf
      ) {
        this.item.cFOP = "1353";
      } else if (this.$store.state.loja.CdUf) {
        this.item.cFOP = "2353";
      } else {
        window.getApp.$emit("APP_LOJA_GET");
      }

      return this.item.cFOP;
    },
    valorParcela: {
      // getter
      get: function () {
        if (
          this.cte.infCte &&
          this.cte.infCte.vPrest &&
          this.cte.infCte.vPrest.vTPrest &&
          this.escritura.parcela
        ) {
          this.escritura.valorParcela =
            parseFloat(this.cte.infCte.vPrest.vTPrest) / parseInt(this.escritura.parcela);

          this.escritura.valorParcela = this.cte.infCte.vPrest.vTPrest;
          return format(this.escritura.valorParcela, this.money);
        }
      },
      // setter
      set: function (value) {
        this.escritura.valorParcela = unformat(value, 2);
        return value;
      },
    },
    nmFornec() {
      if (this.item.fornecedor) {
        return this.item.fornecedor.NmFornec;
      } else {
        return "Fornecedor sem cadastro";
      }
    },
    dtEmissaoFormatted: {
      // getter
      get: function () {
        if (typeof this.dtEmissao !== "undefined") {
          return this.formatDate(new Date(this.dtEmissao).toISOString().substr(0, 10));
        }
      },
      // setter
      set: function (date) {
        this.dtEmissao = this.parseDate(date);
        return date;
      },
    },
    dtEntradaFormatted: {
      // getter
      get: function () {
        if (typeof this.dtEntrada !== "undefined") {
          return this.formatDate(new Date(this.dtEntrada).toISOString().substr(0, 10));
        }
      },
      // setter
      set: function (date) {
        this.dtEntrada = this.parseDate(date);
        return date;
      },
    },
    dtVencimentoFormatted: {
      // getter
      get: function () {
        if (typeof this.dtEmissao !== "undefined") {
          let data = new Date(this.dtEmissao);

          if (this.escritura.quantidadeDias) {
            data.setDate(data.getDate() + this.escritura.quantidadeDias);
            this.escritura.dtVencimento = data.toISOString().substr(0, 10);
          }

          return this.formatDate(data.toISOString().substr(0, 10));
        }
      },
      // setter
      set: function (date) {
        this.escritura.dtVencimento = this.parseDate(date);
        return date;
      },
    },
    baseICMS: {
      // getter
      get: function () {
        if (
          this.cte.infCte &&
          this.cte.infCte.imp &&
          this.cte.infCte.imp.ICMS &&
          this.cte.infCte.imp.ICMS.ICMS00 &&
          this.cte.infCte.imp.ICMS.ICMS00.vBC
        ) {
          this.escritura.baseICMS = this.cte.infCte.imp.ICMS.ICMS00.vBC;
          return format(this.cte.infCte.imp.ICMS.ICMS00.vBC, this.money);
        }
      },
      // setter
      set: function (value) {
        if (
          this.cte.infCte &&
          this.cte.infCte.imp &&
          this.cte.infCte.imp.ICMS &&
          this.cte.infCte.imp.ICMS.ICMS00 &&
          this.cte.infCte.imp.ICMS.ICMS00.vBC
        ) {
          this.cte.infCte.imp.ICMS.ICMS00.vBC = unformat(value, 2);
        }

        return value;
      },
    },
    valorTotal: {
      // getter
      get: function () {
        if (this.cte.infCte && this.cte.infCte.vPrest && this.cte.infCte.vPrest.vTPrest) {
          this.escritura.valorTotal = this.cte.infCte.vPrest.vTPrest;
          return format(this.cte.infCte.vPrest.vTPrest, this.money);
        }
      },
      // setter
      set: function (value) {
        if (this.cte.infCte && this.cte.infCte.vPrest && this.cte.infCte.vPrest.vTPrest) {
          this.cte.infCte.vPrest.vTPrest = unformat(value, 2);
        }

        return value;
      },
    },
    icms: {
      // getter
      get: function () {
        if (
          this.cte.infCte &&
          this.cte.infCte.imp &&
          this.cte.infCte.imp.ICMS &&
          this.cte.infCte.imp.ICMS.ICMS00 &&
          this.cte.infCte.imp.ICMS.ICMS00.vICMS
        ) {
          this.escritura.icms = this.cte.infCte.imp.ICMS.ICMS00.vICMS;
          return format(this.cte.infCte.imp.ICMS.ICMS00.vICMS, this.money);
        }
      },
      // setter
      set: function (value) {
        if (
          this.cte.infCte &&
          this.cte.infCte.imp &&
          this.cte.infCte.imp.ICMS &&
          this.cte.infCte.imp.ICMS.ICMS00 &&
          this.cte.infCte.imp.ICMS.ICMS00.vICMS
        ) {
          this.cte.infCte.imp.ICMS.ICMS00.vICMS = unformat(value, 2);
        }

        return value;
      },
    },
    pICMS: {
      // getter
      get: function () {
        if (
          this.cte.infCte &&
          this.cte.infCte.imp &&
          this.cte.infCte.imp.ICMS &&
          this.cte.infCte.imp.ICMS.ICMS00 &&
          this.cte.infCte.imp.ICMS.ICMS00.pICMS
        ) {
          this.escritura.pICMS = this.cte.infCte.imp.ICMS.ICMS00.pICMS;
          return format(this.cte.infCte.imp.ICMS.ICMS00.pICMS, this.percent);
        }
      },
      // setter
      set: function (value) {
        if (
          this.cte.infCte &&
          this.cte.infCte.imp &&
          this.cte.infCte.imp.ICMS &&
          this.cte.infCte.imp.ICMS.ICMS00 &&
          this.cte.infCte.imp.ICMS.ICMS00.pICMS
        ) {
          this.cte.infCte.imp.ICMS.ICMS00.pICMS = unformat(value, 2);
        }

        return value;
      },
    },
  },
  methods: {
    downloadXML() {
      var pseudoelement = document.createElement("a");

      var filename = this.item.chCTe + ".xml";
      var pseudoelement = document.createElement("a");
      var blob = new Blob([this.item.arquivo.xml], { type: "text/plain" });

      pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
      pseudoelement.setAttribute("download", filename);

      pseudoelement.dataset.downloadurl = [
        "text/plain",
        pseudoelement.download,
        pseudoelement.href,
      ].join(":");
      pseudoelement.draggable = true;
      pseudoelement.classList.add("dragout");

      pseudoelement.click();
    },
    editar() {
      this.abrirEdicao = !this.abrirEdicao;
    },
    salvar() {
      toastr.success("Escriturando CTE...", "Varejão da Sucata");
      let param = this.escritura;
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .put(this.$store.state.urlBase + "v1/contabilidade/escriturarCTE", param)
        .then((response) => {
          if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "Escriturar CTE");
            }
          } else {
            toastr.success(response.data.message, "Escriturar CTE");
            this.atualizar();
          }
        });
      this.dialog = false;
    },
    atualizar() {
      this.param = {};
      window.getApp.$emit("APP_RECORD_UPDATED");

      this.dialog = false;
    },
    buscarParams() {
      toastr.success("Buscando parâmetros para faturas...", "Varejão da Sucata");
      // console.log(this.item.arquivo.xml);
      this.escritura.evento = "55";
      this.escritura.ccusto = "1";
      this.escritura.quantidadeDias = 20;
      this.escritura.parcela = 1;
      this.escritura.CdFormaPagto = "5";
      window.getApp.$emit("APP_GET_EVENTOS", this.trans);
      window.getApp.$emit("APP_GET_CCUSTO", this.escritura.evento);
      window.getApp.$emit("APP_METODO_PAGAMENTO_GET");

      if (this.item.tpCTe !== null) {
        this.item.especie = "CTE";
      }

      if (this.item.saida) {
        this.item.tipo = "V";
        this.item.observacao = "REF NFE " + this.item.saida.NrNf;
      }

      this.dtEmissao = new Date(this.item.arquivo.DtEmissao).toISOString().substr(0, 10);
      this.dtEntrada = new Date(this.item.arquivo.DtEmissao).toISOString().substr(0, 10);
      this.dtVencimento = this.item.arquivo.DtEmissao;

      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(
          this.$store.state.urlBase + "v1/contabilidade/consultaCFOPSelect/" + this.trans
        )
        .then((response) => {
          if (response.data) {
            this.cfops = response.data;
          }
        });
      this.$http
        .get(this.$store.state.urlBase + "v1/contabilidade/getObjCTe/" + this.item.chCTe)
        .then((response) => {
          if (response.data) {
            this.cte = response.data.CTe;
            // console.log(this.cte);
          }
        });

      if (this.item.fornecedor) {
        this.escritura.CdFornec = this.item.fornecedor.CdFornec;
        this.$http
          .get(
            this.$store.state.urlBase +
              "v1/financeiro/paramFatura/" +
              this.item.fornecedor.CdFornec
          )
          .then((response) => {
            if (response.data) {
              this.paramFatura = response.data;
              this.escritura.quantidadeDias = this.paramFatura.quantidadeDias;
              this.escritura.CdFormaPagto = this.paramFatura.CdFormaPagto;
            }
          });
      }

      //Ajustando objeto
      this.escritura.ChkPisCofins = 0;
      this.escritura.especie = this.item.especie;
      this.escritura.cFOP = this.cFOP;
      this.escritura.tipo = this.item.tipo;
      this.escritura.observacao = this.item.observacao;
      this.escritura.dtEmissao = this.dtEmissao;
      this.escritura.dtEntrada = this.dtEntrada;
      this.escritura.dtVencimento = this.dtVencimento;
      this.escritura.NmSerieNf = this.item.arquivo.NmSerieNf;
      this.escritura.NrNf = this.item.arquivo.NrNf;
      this.escritura.chCTe = this.item.chCTe;
      this.escritura.parcela = 1;
      this.escritura.percentual = 100;

      // console.log(this.escritura);
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2).toLocaleString("pt-BR");
    },
  },
  filters: {
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
  mounted() {},
  updated() {
    // console.log(this.item);
  },
};
</script>
