<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex lg12>
                    <v-card>
                        <v-toolbar flat color="white">
                        <!-- <v-toolbar-title>My CRUD</v-toolbar-title>
                            <v-divider
                                class="mx-2"
                                inset
                                vertical
                            ></v-divider> -->
                            <v-spacer></v-spacer>
                            <usuario></usuario>
                        </v-toolbar>
                        <v-toolbar card color="white">
                        <v-text-field
                        flat
                        solo
                        prepend-icon="search"
                        placeholder="Digite algo"
                        v-model="search"
                        hide-details
                        class=""
                        ></v-text-field>     
                        <v-btn icon>
                            <v-icon>filter_list</v-icon>
                        </v-btn>         
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                        <v-data-table
                            :headers="complex.headers"
                            :search="search"
                            :items="complex.items"
                            :rows-per-page-items="[10,25,50,{text:'All','value':-1}]"
                            class="elevation-1"
                            item-key="name"
                            select-all
                            v-model="complex.selected"
                            >
                            <template slot="items" slot-scope="props">
                            <td>
                            <v-checkbox
                                primary
                                hide-details
                                v-model="props.selected"
                            ></v-checkbox>
                            </td>              
                            <!-- <td>
                                <v-avatar size="32">
                                <img :src="props.item.avatar" alt="">
                                </v-avatar> 
                            </td> -->
                            <td>{{ props.item.id }}</td>
                            <td>{{ props.item.NmCompleto }}</td>
                            <td>{{ props.item.CdUsuario }}</td>
                            <td>{{ props.item.lastUpdate | formatDate('DD/MM/YYYY HH:mm:ss')}}</td>
                            <td>
                                <v-btn depressed outline icon fab dark color="primary" small @click.native="editar(props.item)">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                <!-- <v-btn depressed outline icon fab dark color="pink" small>
                                <v-icon>delete</v-icon>
                                </v-btn> -->
                            </td>
                            </template>
                        </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>       
        </v-container>
    </div>
</template>
<script>
import toastr from 'toastr'
import * as moment from "moment";
import 'moment/locale/pt-br'
moment.locale('pt-BR')
import Usuario from '@/components/usuario/Usuario.vue'

export default {
  components:{
      Usuario
  },
  data () {
    return {
      dialog: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Nome Completo',
            value: 'NmCompleto'
          },
          {
            text: 'Usuário',
            value: 'CdUsuario'
          },
          {
            text: 'Última atualização',
            value: 'lastUpdate'
          },
          {
            text: 'Ações',
            value: ''
          }
        ],
        items: []
      },
    }
  },
   methods: {
       getUsuarios () {
           toastr.success('Atualizando lista de usuários...','Varejão da Sucata')
           this.$http.get(this.$store.state.urlBase+'users')
                        .then(response => {
                            this.complex.items = response.data
                        })
          window.getApp.$emit('APP_SETORES_GET')
       },
       editar (user) {
           this.$store.state.userEdit = user
           this.$store.state.dialogUser = true
       }
   },
  created () {
      delete window.getApp._events.APP_RECORD_UPDATED
      this.getUsuarios ()
      window.getApp.$on('APP_RECORD_UPDATED',this.getUsuarios)
      window.getApp.$on('APP_USER_UPDATED',this.getUsuarios)
  },
  updated () {
    //   this.getCategorias ()
  },
  filters: {
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  }
}
</script>