<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex sm12>
                <h3>Itens do almoxarifado</h3>
                </v-flex>
                <v-flex lg12>
                    <v-card>
                        <v-toolbar flat color="white">
                        <!-- <v-toolbar-title>My CRUD</v-toolbar-title>
                            <v-divider
                                class="mx-2"
                                inset
                                vertical
                            ></v-divider> -->
                            <v-spacer></v-spacer>
                            <item-modal></item-modal>
                        </v-toolbar>
                        <v-toolbar card color="white">
                        <v-text-field
                        flat
                        solo
                        prepend-icon="search"
                        placeholder="Digite algo"
                        v-model="search"
                        hide-details
                        class=""
                        ></v-text-field>     
                        <v-btn icon>
                            <v-icon>filter_list</v-icon>
                        </v-btn>         
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                        <v-data-table
                            :headers="complex.headers"
                            :search="search"
                            :items="complex.items"
                            :rows-per-page-items="[10,25,50,{text:'All','value':-1}]"
                            class="elevation-1"
                            item-key="name"
                            v-model="complex.selected"
                            >
                            <template slot="items" slot-scope="props">
                            <td v-bind:class="{ alerta: props.item.alerta }">{{ props.item.id }}</td>
                            <td v-bind:class="{ alerta: props.item.alerta }">{{ props.item.descricao }}</td>
                            <td v-bind:class="{ alerta: props.item.alerta }" align="center">{{ props.item.quantidadeAtual | formatNumber}}</td>
                            <td v-bind:class="{ alerta: props.item.alerta }" align="center">{{ props.item.quantidadeMinima | formatNumber }}</td>
                            <td v-bind:class="{ alerta: props.item.alerta }" align="center">{{ props.item.valorUltEntrada | formatNumber }}</td>
                            <td v-bind:class="{ alerta: props.item.alerta }" align="center">{{ props.item.categoria.descricao  }}</td>
                            <td >
                                <v-btn depressed outline icon fab dark color="primary" small @click.native="editar(props.item)">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                <v-btn depressed outline icon fab dark color="pink" small @click.native="excluir(props.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                                <inventario-modal :item="props.item"></inventario-modal>
                            </td>
                            </template>
                        </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>       
        </v-container>
    </div>
</template>
<script>
import toastr from 'toastr'
import ItemModal from '@/components/almoxarifado/Item.vue'
import InventarioModal from '@/components/almoxarifado/Inventario.vue'
import * as moment from "moment";
import 'moment/locale/pt-br'
moment.locale('pt-BR')

export default {
  components:{
    ItemModal,
    InventarioModal
  },
  data () {
    return {
      dialog: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Descrição',
            value: 'descricao'
          },
          {
            text: 'Quantidade Atual',
            value: 'quantidadeAtual'
          },
          {
            text: 'Quantidade Mínima',
            value: 'quantidadeMinima'
          },
          {
            text: 'Valor da última entrada',
            value: 'valorUltEntrada'
          },
          {
            text: 'Categoria',
            value: 'categoria.descricao'
          },
          {
            text: 'Ações',
            value: ''
          }
        ],
        items: []
      },
    }
  },
   methods: {
       getList () {
           this.complex.items = []
           toastr.success('Atualizando itens do almoxarifado...','Varejão da Sucata')
           this.$http.get(this.$store.state.urlBase+'almoxarifado/item')
                        .then(response => {
                            this.complex.items = response.data
                        })
       },
       editar (item) {
           item.categoria_id = parseInt(item.categoria_id)
           this.$store.state.item = item
           this.$store.state.dialogItem = true
       },
       excluir (item) {
         var http = this.$http
         var url = this.$store.state.urlBase
         toastr.warning("<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",'Deseja realmente excluir este item?',
          {
              allowHtml: true,
              onclick: function (toast) {
                var value = toast.target.value

                if (value == 'Sim') {
                  http.delete(url+'almoxarifado/item/'+item.id)
                  .then(response => {
                    console.log(response.data.message)
                    toastr.success(response.data.message,'Varejão da Sucata')
                    window.getApp.$emit('APP_ITEM_UPDATED')
                  })  
                }
                toastr.remove()
                toastr.clear()
              }

          })
       }
   },
  created () {
      this.getList ()
      window.getApp.$emit('APP_CATEGORIAS_GET');
      window.getApp.$emit('APP_UNIDADES_GET');
      delete window.getApp._events.APP_ITEM_UPDATED
      delete window.getApp._events.APP_RECORD_UPDATED
      window.getApp.$on('APP_ITEM_UPDATED',this.getList)
      window.getApp.$on('APP_RECORD_UPDATED',this.getList)
  },
  updated () {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value){
          let number = parseFloat(value)
          return number.toFixed(2)
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  }
}
</script>
<style>
.alerta{
    background-color: red;
    color: white
}
</style>
