<template>
    <v-dialog v-model="$store.state.dialogPrecoPadrao" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark fab small class="hidden-md-and-up">
            <v-icon >add_circle_outline</v-icon>
        </v-btn>
        <v-btn slot="activator" color="primary" dark class="hidden-sm-and-down">
            <v-icon left>add_circle_outline</v-icon> Novo Preço Padrão de Sucata
        </v-btn>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Preço Padrão de Sucata</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm12 md4>
                                <v-autocomplete
                                    :disabled="typeof($store.state.precoPadraoItem.IdPrecoPadraoSucata) !== 'undefined'"
                                    v-model="$store.state.precoPadraoItem.IdTipoSucata"
                                    :items="$store.getters.tiposucata"
                                    item-text="NmTipoSucata"
                                    item-value="IdTipoSucata"
                                    label="Tipo Sucata *"
                                    required
                                    :rules="tipoSucataRules"
                                    ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md4>
                                <v-autocomplete
                                    :disabled="typeof($store.state.precoPadraoItem.IdPrecoPadraoSucata) !== 'undefined'"
                                    v-model="$store.state.precoPadraoItem.IdTipoFrete"
                                    :items="$store.getters.tiposfrete"
                                    item-text="NmTipoFrete"
                                    item-value="IdTipoFrete"
                                    label="Tipo Frete *"
                                    required
                                    :rules="tipoFreteRules"
                                    ></v-autocomplete>
                            </v-flex>
                            <!-- <v-flex xs12 sm12 md4>
                                <moeda 
                                    label="Preço Padrão *" 
                                    :v-model="$store.state.precoPadraoItem.VlPrecoPadrao" 
                                    v-bind:value="$store.state.precoPadraoItem.VlPrecoPadrao"
                                    v-bind="money"></moeda>
                            </v-flex> -->
                            <v-flex xs12 sm12 md4>
                                <v-text-field 
                                required
                                type="tel"
                                persistent-hint
                                v-model="VlPrecoPadrao"
                                @input="change"
                                :rules="precoPadraoRules"
                                label="Preço Padrão *" ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <small>* campo obrigatório</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click.native="atualizar()">Cancelar</v-btn>
                    <v-btn color="blue darken-1" flat @click.native="update()" v-if="$store.state.precoPadraoItem.IdPrecoPadraoSucata" :disabled="!valid">Salvar</v-btn>
                    <v-btn color="blue darken-1" flat @click.native="insert()" v-if="!$store.state.precoPadraoItem.IdPrecoPadraoSucata" :disabled="!valid">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
import {Moeda} from '@/components/VMoeda'
import {format, unformat} from '@/components/VMoeda/utils'
// import {Money} from 'v-money'
// import {VMoney} from 'v-money'
export default {
    components: {Moeda},
    // directives: {money: VMoney},
    data () {
        return {
            valid: false,
            tipoSucataRules: [
                v => !!v || 'Tipo de Sucata é obrigatório!'
            ],
            tipoFreteRules: [
                v => !!v || 'Tipo de Frete é obrigatório!'
            ],
            precoPadraoRules: [
                v => !!v || 'Preço Padrão é obrigatório!',
                v => (v && unformat(v,2) > 0) || 'Preço padrão precisa ser maior que zero'
            ],
            modalDataEntrada:false,
            modalDataValidade:false,
            dialog: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },
    computed: {
        VlPrecoPadrao: {
            // getter
            get: function () {
                if(typeof(this.$store.state.precoPadraoItem.VlPrecoPadrao) !== 'undefined'){
                    //return this.$store.state.precoPadraoItem.VlPrecoPadrao
                    return format(this.$store.state.precoPadraoItem.VlPrecoPadrao,this.money)
                }
                
            },
            // setter
            set: function (value) {
                this.$store.state.precoPadraoItem.VlPrecoPadrao = unformat(value,2)
                return value
            }
        }
    },
    methods : {
        change (value) {
            var vm =   this
            console.log('valor: '+unformat(value,2))
            this.$emit('input', this.masked ? value: unformat(value, 2))
        },
        update (){
            if (this.$refs.form.validate()) {
                let obj = {}
                obj.IdPrecoPadraoSucata = this.$store.state.precoPadraoItem.IdPrecoPadraoSucata
                obj.VlPrecoPadrao = this.$store.state.precoPadraoItem.VlPrecoPadrao
                console.log(obj)
                this.$http.put(this.$store.state.urlBase+'v1/comercial/precoPadraoEntrada/'+obj.IdPrecoPadraoSucata,obj)
                  .then(response => {
                     if(typeof response.data.errors !== 'undefined'){
                          //Erros de Validação
                          for (var error in response.data.errors) {
                              toastr.error(response.data.errors[error],'Preço Padrão de Sucata')
                          }
                     }else{
                         console.log(response)
                         this.atualizar()
                     }
                   
              })
            }
            
        },
        insert(){
            if (this.$refs.form.validate()) {
                let obj = this.$store.state.precoPadraoItem
                obj.IdFornecedor = this.$store.state.fornecedor.CdFornec
                console.log(obj)
                 this.$http.post(this.$store.state.urlBase+'v1/comercial/precoPadraoEntrada',obj)
                  .then(response => {
                     if(typeof response.data.errors !== 'undefined'){
                          //Erros de Validação
                          for (var error in response.data.errors) {
                              toastr.error(response.data.errors[error],'Preço Padrão de Sucata')
                          }
                     }else{
                         console.log(response)
                         this.atualizar()
                     }
                   
              })
            }
        },
        atualizar () {
            this.$store.state.precoPadraoItem = {}
            this.VlPrecoPadrao = 0
            window.getApp.$emit('APP_PRECO_PADRAO_FORNECEDOR_GET')
            this.$store.commit('fecharModalPrecoPadrao')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        formatNumber(value){
            let number = parseFloat(value)
            return number.toLocaleString('pt-BR')
        },
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toLocaleString('pt-BR')
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
        }
  },
    mounted () {
        
    }
}
</script>
<style>
    #valor-padrao{
        text-align: right;
    }
    /* @import url(https://cdnjs.cloudflare.com/ajax/libs/spectre.css/0.2.14/spectre.min.css); */
    .v-money{
        text-align:right;
        color: rgba(0,0,0,0.87);
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        line-height: 20px;
        padding: 8px 0 8px;
        max-width: 100%;
        min-width: 0px;
        width: 100%;
        max-height: 32px;
        background-color: transparent;
        border-style: none;
        box-sizing: border-box;
        font-family:Roboto, sans-serif;
        font-size:16px;
    }
    /* input{font-family:monospace;font-size:1.8rem!important} */
</style>
