<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    color="indigo lighten-4"
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          outline
          icon
          fab
          dark
          color="primary"
          small
          v-on="on"
          :disabled="escriturado"
        >
          <v-icon>cloud_upload</v-icon>
        </v-btn>
      </template>
      <span>Upload XML da NFe</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Upload XML da NFe</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items>
          <v-btn dark flat @click="dialog = false">Save</v-btn>
        </v-toolbar-items> -->
      </v-toolbar>
      <!-- <v-card-title class="headline">Upload XML da NFe</v-card-title> -->
      <v-card-text class="indigo lighten-4">
        <template>
          <v-sheet
            ref="dzone"
            tabindex="0"
            title="Clique ou arraste um arquivo do seu computador!"
            color="indigo lighten-4"
            width="100%"
            style="cursor: pointer"
            height="500"
            class="pa-5"
            @click="dropzoneClick()"
            @keypress.prevent="dropzoneClick()"
            @dragenter.prevent="dragover = true"
            @dragover.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
            @drop.prevent="dragFile($event)"
          >
            <input
              ref="upload"
              type="file"
              accept="text/xml"
              style="display: none"
              @change="dragFile"
            />
            <v-layout row justify-center>
              <v-icon v-if="!dragover" color="indigo darken-2" size="75"
                >mdi-cloud-upload-outline</v-icon
              >
              <v-icon v-if="dragover" color="indigo darken-2" size="75"
                >mdi-book-plus</v-icon
              >
            </v-layout>
            <v-layout row justify-center>
              <span class="title indigo--text text--darken-2">{{ fileName }}</span>
              <!-- <span class="title indigo--text text--darken-2">{{ fileObject }}</span> -->
            </v-layout>
          </v-sheet>
        </template>
      </v-card-text>
      <v-card-actions class="elevation-0 pt-3">
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click="dialog = false">Não</v-btn>
        <v-btn color="primary darken-1" flat @click="submeter()">Sim</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          Aguarde o processamento do XML
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import toastr from "toastr";
// import { Component, Emit } from "vue-property-decorator";
export default {
  name: "UploadXML",
  components: {
    // Component,
    // Emit,
  },
  data() {
    return {
      dialog: false,
      formUpload: false,
      dragover: false,
      fileObject: {},
      fileName: "Arraste e solte ou clique para fazer o upload do arquivo!",
      url: "",
      files: [],
      name: "",
      size: "",
      type: "",
      lastModifiedDate: "",
      loading: false,
    };
  },
  props: {
    chNFe: String,
    escriturado: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fr() {
      // window.onUploadSelectedFileClick = this.onUploadSelectedFileClick;
      // If valid, continue
      let url = this.url;
      let fr = new FileReader();
      // console.log(this.$data);
      fr.readAsDataURL(this.files[0]);
      fr.addEventListener("load", () => {
        this.url = fr.result;
        this.fileObject = this.files[0]; // this is an file that can be sent to server...
        this.onUploadSelectedFileClick();
        // console.log(this.$data);
        // window.onUploadSelectedFileClick();
      });
      // setTimeout(function () {
      //   window.onUploadSelectedFileClick();
      // }, 1);
      return fr;
    },
    result() {
      //console.log(this.fr);
      //console.log(this.url);
      return this.fr;
    },
  },
  methods: {
    onUploadSelectedFileClick() {
      //   console.log("teste");
      toastr.success("Enviando arquivo...", "Varejão da Sucata");
      this.loading = true;
      this.fileObject.url = this.url;
      this.fileObject.TxChAcessoNfe = this.chNFe;

      // A file is not chosen!
      if (!this.fileObject) {
        alert("No file!!");
      }
      // DO YOUR JOB HERE with fileObjectToUpload
      // https://developer.mozilla.org/en-US/docs/Web/API/File/File
      this.name = this.fileObject.name;
      this.size = this.fileObject.size;
      this.type = this.fileObject.type;
      this.lastModifiedDate = this.fileObject.lastModifiedDate;
      // DO YOUR JOB HERE with fileObjectToUpload
      let fileObjectToUpload = {};
      fileObjectToUpload.url = this.url;
      fileObjectToUpload.TxChAcessoNfe = this.chNFe;
      fileObjectToUpload.name = this.name;
      fileObjectToUpload.size = this.size;
      fileObjectToUpload.type = this.type;
      fileObjectToUpload.lastModifiedDate = this.lastModifiedDate;
      // console.log(fileObjectToUpload);

      //Enviar para a api o XML e trazer o json pronto para escrituração

      this.$http
        .post(this.$store.state.urlBase + "v1/sefaz/uploadXML", {
          fileObjectToUpload,
        })
        .then((response) => {
          if (response.data.error) {
            toastr.error(response.data.error, "Varejão da Sucata");
          } else {
            toastr.success("Arquivo Vinculado com Sucesso!", "Varejão da Sucata");
            this.dialog = false;
            this.dragover = false;
            this.fileObject = {};
          }
        });

      this.loading = false;
    },
    onFilePicked(event) {
      this.files = event.target.files;
      if (this.files[0] !== undefined) {
        this.fileName = this.files[0].name;
        // Check validity of file
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        this.fr;
        // //console.log(this.fr);
        // this.url = fr.result;
        this.fileObject = this.files[0];
      } else {
        this.fileName = "";
        this.fileObject = null;
        this.url = "";
      }
      //   //console.log(this.files);
      //   //console.log(this.fileObject);
      //   this.onUploadSelectedFileClick();
    },
    filesSelected(fileList) {
      //   this.dragover = false;
      this.formUpload = true;

      if (fileList[0] !== undefined) {
        this.fileName = fileList[0].name;
        // Check validity of file
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        this.fr;
        //console.log(this.fr);
        // this.url = fr.result;
        this.fileObject = fileList[0];
      } else {
        this.fileName = "";
        this.fileObject = null;
        this.url = "";
      }
    },
    dropzoneClick() {
      const fileupload = this.$refs.upload;
      fileupload.click();
    },
    dragFile(e) {
      this.loading = true;
      const target = e.target;
      //   //console.log(e);
      if (target.files) {
        this.files = target.files;
        this.filesSelected(target.files);
      }
      const dragevent = e;
      if (dragevent.dataTransfer) {
        this.files = dragevent.dataTransfer.files;
        this.filesSelected(dragevent.dataTransfer.files);
      }
    },
  },
};
</script>
