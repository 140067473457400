<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn depressed outline icon fab dark color="primary" small v-on="on">
          <v-icon>post_add</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Documentos</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Envio de Documentos</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Selecione o arquivo MTR *"
                @click="onPickFile"
                v-model="fileName"
                prepend-icon="attach_file"
              ></v-text-field>
              <!-- Hidden -->
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="*/*"
                @change="onFilePicked"
              />
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Documento do motorista *"
                v-model="fileNameMotorista"
                @click="onPickFileMotorista"
                prepend-icon="badge"
              ></v-text-field>
              <input
                type="file"
                style="display: none"
                ref="fileInputMotorista"
                accept="*/*"
                @change="onFilePickedMotorista"
              />
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Documento do Cavalo *"
                v-model="cavalo"
                @click="onPickFile"
                prepend-icon="mdi-truck-flatbed"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Documento da Carreta 1"
                v-model="carreta_um"
                @click="onPickFile"
                prepend-icon="mdi-truck-trailer"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Documento da Carreta 2"
                v-model="carreta_dois"
                @click="onPickFile"
                prepend-icon="mdi-truck-trailer"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Check List de Qualidade e Segurança *"
                v-model="checklist"
                @click="onPickFile"
                prepend-icon="checklist"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Planilha de credenciamento"
                v-model="planilha"
                @click="onPickFile"
                prepend-icon="mdi-microsoft-excel"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <!-- <small>* campo obrigatório</small> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click.stop="onUploadSelectedFileClick"
          :loading="loading"
          >Enviar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      valor: 16,
      fileName: "",
      fileNameMotorista: "",
      url: "",
      urlMotorista: "",
      fileObjectMotorista: null,
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      mrtValue: "",
      lastModifiedDate: "",
      loading: false,
      cavalo: "",
      carreta_um: "",
      carreta_dois: "",
      checklist: "",
      planilha: "",
    };
  },
  components: {},
  computed: {},
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onPickFileMotorista() {
      this.$refs.fileInputMotorista.click();
    },
    onFilePickedMotorista(event) {
      const files = event.target.files;
      if (files[0] !== undefined) {
        this.fileNameMotorista = files[0].name;
        // Check validity of file
        if (this.fileNameMotorista.lastIndexOf(".") <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.urlMotorista = fr.result;
          this.fileObjectMotorista = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.fileNameMotorista = "";
        this.fileObjectMotorista = null;
        this.urlMotorista = "";
      }
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        // Check validity of file
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.url = fr.result;
          this.fileObject = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.fileName = "";
        this.fileObject = null;
        this.url = "";
      }
    },
    onUploadSelectedFileClick() {
      toastr.success("Enviando arquivo...", "Varejão da Sucata");
      this.loading = true;
      this.fileObject.url = this.url;
      this.fileObject.TxChAcessoNfe = this.$store.state.mtr.TxChAcessoNfe;

      // A file is not chosen!
      if (!this.fileObject) {
        alert("Sem MTR Anexado");
      }
      if (!this.fileObjectMotorista) {
        alert("Documento do Motorista não Anexado");
      }
      // DO YOUR JOB HERE with fileObjectToUpload
      // https://developer.mozilla.org/en-US/docs/Web/API/File/File
      this.name = this.fileObject.name;
      this.size = this.fileObject.size;
      this.type = this.fileObject.type;
      this.lastModifiedDate = this.fileObject.lastModifiedDate;
      // DO YOUR JOB HERE with fileObjectToUpload
      let fileObjectToUpload = {};
      fileObjectToUpload.url = this.url;
      fileObjectToUpload.TxChAcessoNfe = this.$store.state.mtr.TxChAcessoNfe;
      fileObjectToUpload.name = this.name;
      fileObjectToUpload.size = this.size;
      fileObjectToUpload.type = this.type;
      fileObjectToUpload.lastModifiedDate = this.lastModifiedDate;

      //Documento do Motorista
      let fileObjectMotorista = {};
      fileObjectMotorista.url = this.urlMotorista;
      fileObjectMotorista.TxChAcessoNfe = this.$store.state.mtr.TxChAcessoNfe;
      fileObjectMotorista.name = this.fileObjectMotorista.name;
      fileObjectMotorista.size = this.fileObjectMotorista.size;
      fileObjectMotorista.type = this.fileObjectMotorista.type;
      fileObjectMotorista.lastModifiedDate = this.fileObjectMotorista.lastModifiedDate;
      //console.log(fileObjectToUpload);
      let envio = {
        mtr: fileObjectToUpload,
        motorista: fileObjectMotorista,
      };
      console.log(envio);
      this.$http
        .post(this.$store.state.urlBase + "v1/comercial/NfSaidas/AnexarDocumentos", {
          envio,
        })
        .then((response) => {
          if (response.data.error) {
            toastr.error(response.data.error, "Varejão da Sucata");
          } else {
            toastr.success("Arquivo Vinculado com Sucesso!", "Varejão da Sucata");
            this.atualizar();
          }
        });

      this.loading = false;
    },
    enviar() {
      // let entrada = this.$store.state.entrada
    },
    atualizar() {
      this.$store.state.mtr = {};
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      // this.$store.state.dialogMTR = false
      window.getApp.$emit("APP_RECORD_UPDATED");
      // this.$store.commit("fecharModalMTR");
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },

    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
  mounted() {},
};
</script>
