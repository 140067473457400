<template>
    <v-dialog v-model="$store.state.dialogInventario[item.id]" persistent max-width="500px">
        <v-btn slot="activator" depressed outline icon fab dark color="blue" small>
            <v-icon>assignment</v-icon> 
        </v-btn>
        <v-card>
            <v-card-title>
                <span class="headline">Inventário</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Descrição do Item" required v-model="item.descricao" :disabled="typeof(item.id) !== 'undefined'"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field label="Quantidade Atual" required v-model="item.quantidadeAtual" :disabled="typeof(item.id) !== 'undefined'"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field label= "Quantidade Inventário *" required v-model="item.quantidadeInventario"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <small>* campo obrigatório</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Cancelar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="salvar()">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
export default {
    props:['item'],
    methods : {
        salvar (){
            let item = this.item
            this.$http.put(this.$store.state.urlBase+'almoxarifado/item/inventario',item)
                 .then(response => {
                    if(typeof response.data.errors !== 'undefined'){
                         //Erros de Validação
                         for (var error in response.data.errors) {
                             toastr.error(response.data.errors[error],'Inventário de Itens')
                         }
                    }else{
                        this.atualizar()
                    }
                   
             })
        },
        atualizar () {
            // console.log(this.$store.state.dialogInventario)
            window.getApp.$emit('APP_ITEM_UPDATED')
            this.$store.state.dialogInventario = []
            // console.log(this.$store.state.dialogInventario)
        }
    }
}
</script>
