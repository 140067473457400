<template>
    <v-dialog v-model="$store.state.dialogUser" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Usuário
        </v-btn>
        <v-card>
            <v-toolbar card prominent extended color="primary" dark="">
                <v-toolbar-title class="body-2">Cadastro de usuário</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="$store.state.dialogUser = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
			<v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation><!--v-model="valid" ref="form" lazy-validation -->
                    <v-layout row wrap>
                        <v-flex lg12 sm12>
                            <v-text-field label="Nome Completo" v-model="$store.state.userEdit.NmCompleto">
                            </v-text-field>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Nome de Guerra" v-model="$store.state.userEdit.NmGuerra">
                            </v-text-field>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Usuário" v-model="$store.state.userEdit.CdUsuario">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-select
                                v-model="$store.state.userEdit.setor.CdSetor"
                                :items="$store.state.setores"
                                label="Setor *"
                                required
                                item-text="NmSetor"
                                item-value="CdSetor"
                                ></v-select>
                        </v-flex>
                        <v-flex lg12 sm12>
                            <v-text-field label="Senha" v-model="$store.state.userEdit.password" type="password">
                            </v-text-field>
                        </v-flex>
                    </v-layout>        
                </v-form>
            </v-card-text>  
            <v-card-actions class="pb-3">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="cancelar()">Cancelar</v-btn>
                <v-btn color="primary"  @click.native="salvar()" v-if="!$store.state.userEdit.id">Salvar</v-btn>
                <v-btn color="primary"  @click.native="update()" v-else>Atualizar</v-btn>
            </v-card-actions>	
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:'Usuario',
    data () {
        return {
            dialog: false,
            valid: true,
        }
    },
    methods : {
        salvar (){
            let user = this.$store.state.userEdit
            this.$http.post(this.$store.state.urlBase+'user',user)
                .then(response => {
                   this.atualizar()
            })
        },
        update (){
            let user = this.$store.state.userEdit
            let setor = user.setor
            console.log('Setor:')
            console.log(setor)
            user.setor = {}
            user.setor.CdSetor = setor.CdSetor
            user.setor.CdUsuario = setor.CdUsuario
            console.log('Usuário:')
            console.log(user)
            // delete user.setor
            this.$http.put(this.$store.state.urlBase+'user',user)
                 .then(response => {
                    this.atualizar()
            })
        },
        cancelar() {
            this.$store.state.dialogUser = false
            this.$store.state.userEdit = {
                 setor: {
                     CdSetor: null
                 }
            }
        },
        atualizar () {
            this.$store.state.userEdit = {
                 setor: {
                     CdSetor: null
                 }
            }
            window.getApp.$emit('APP_USER_UPDATED')
            this.$store.state.dialogUser = false
        }
    },
    created () {
        // console.log(!this.$store.state.categoria.id)
    }
}
</script>
