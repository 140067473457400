var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm12":""}},[_c('h3',[_vm._v("Notas Fiscais Destinadas")])]),_c('v-flex',{attrs:{"lg12":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer')],1),_c('v-toolbar',{attrs:{"card":"","color":"white"}},[_c('v-text-field',{attrs:{"flat":"","solo":"","prepend-icon":"search","placeholder":"Digite algo","hide-details":"","classeantiga":"hidden-sm-and-down"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("filter_list")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.complex.headers,"search":_vm.search,"items":_vm.complex.items,"rows-per-page-items":[10, 25, 50, { text: 'Todos', value: -1 }],"rows-per-page-text":"Linhas por página","item-key":"chNFe","pagination":_vm.complex.pagination,"select-all":""},on:{"update:pagination":function($event){return _vm.$set(_vm.complex, "pagination", $event)}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_c('th',[_c('v-checkbox',{attrs:{"input-value":props.all,"indeterminate":props.indeterminate,"primary":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll(props)}}})],1),_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
                      'column sortable',
                      'text-xs-left',
                      _vm.complex.pagination.descending ? 'desc' : 'asc',
                      header.value === _vm.complex.pagination.sortBy ? 'active' : '' ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")])],1)})],2)]}},{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.selected},on:{"click":function($event){props.selected = !props.selected}}},[_c('td',[_c('v-checkbox',{attrs:{"primary":"","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})],1),_c('td',[_vm._v(_vm._s(props.item.NSU))]),_c('td',{attrs:{"width":"10%"}},[_vm._v(_vm._s(props.item.chNFe))]),_c('td',[_vm._v(_vm._s(props.item.xNome)+" - "+_vm._s(props.item.cnpjFormated))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.dhEmi,"DD/MM/Y hh:mm:ss"))+" ")]),_c('td',[_vm._v(_vm._s(props.item.NrNf))]),_c('td',[_vm._v(_vm._s(props.item.NmSerieNf))]),_c('td',[(props.item.escriturada == '1')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('span',[_vm._v("Escriturado")])]):_vm._e(),(props.item.cSitConf == '4')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("sports_score")])]}}],null,true)},[_c('span',[_vm._v("Ciência da Operação")])]):_vm._e(),(props.item.cSitConf == '1')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("cloud_done")])]}}],null,true)},[_c('span',[_vm._v("Confirmação da Operação")])]):_vm._e(),(props.item.cSitConf == '2')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("search_off")])]}}],null,true)},[_c('span',[_vm._v("Desconhecimento da Operação")])]):_vm._e(),(props.item.cSitConf == '3')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("cloud_off")])]}}],null,true)},[_c('span',[_vm._v("Operação não realizada")])]):_vm._e(),(props.item.escriturada == '0')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("cancel")])]}}],null,true)},[_c('span',[_vm._v("Não Escriturado")])]):_vm._e(),_c('br'),(props.item.cSitNFe == '3')?_c('span',[_vm._v("NFe Cancelada")]):_vm._e()],1),_c('td',[_c('div',{staticClass:"text-xs-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","fab":"","small":"","flat":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("list")])],1)]}}],null,true),model:{value:(props.item.menuNFe),callback:function ($$v) {_vm.$set(props.item, "menuNFe", $$v)},expression:"props.item.menuNFe"}},[_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Funções NF-e")]),_c('v-list-tile',[_c('v-list-tile-action',[_c('danfe-view',{attrs:{"item":props.item,"textoBotao":"Ver NFe","usarChave":true}})],1),_c('v-list-tile-title',[_vm._v("Ver NFe")])],1),_c('v-list-tile',{attrs:{"disabled":props.item.escriturada == '1'}},[_c('v-list-tile-action',[_c('upload-xml',{key:props.item.chNFe + '_xml',attrs:{"chNFe":props.item.chNFe,"escriturado":props.item.escriturada == '1'}})],1),_c('v-list-tile-title',[_vm._v("Upload XML")])],1),_c('v-list-tile',[_c('v-list-tile-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","outline":"","icon":"","fab":"","small":"","color":"primary"},nativeOn:{"click":function($event){return _vm.downloadXML(props.item)}}},on),[_c('v-icon',[_vm._v("archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Download XML")])])],1),_c('v-list-tile-title',[_vm._v("Download XML")])],1),_c('v-list-tile',[_c('v-list-tile-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","outline":"","icon":"","fab":"","small":"","color":"primary"},nativeOn:{"click":function($event){return _vm.consultarSefaz(props.item)}}},on),[_c('v-icon',[_vm._v("travel_explore")])],1)]}}],null,true)},[_c('span',[_vm._v("Consultar Sefaz")])])],1),_c('v-list-tile-title',[_vm._v("Consultar Sefaz")])],1),_c('v-list-tile',{attrs:{"disabled":props.item.escriturada == '1'}},[_c('v-list-tile-action',[_c('escriturar-nfe',{attrs:{"item":props.item,"textoBotao":"Escriturar NFe","usarChave":true}})],1),_c('v-list-tile-title',[_vm._v("Escriturar NFe")])],1),_c('v-list-tile',{attrs:{"disabled":props.item.escriturada != '1'}},[_c('v-list-tile-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","outline":"","icon":"","fab":"","small":"","color":"red"}},on),[_c('v-icon',[_vm._v("delete_forever")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir Escrituração")])])],1),_c('v-list-tile-title',[_vm._v("Excluir Escrituração")])],1),_c('v-subheader',[_vm._v("Manifestação Destinatário")]),_vm._l((_vm.filteredTipoManifestacao(
                                props.item.cSitConf
                              )),function(op){return _c('v-list-tile',{key:op.tipo + '_manifestacao'},[_c('v-list-tile-action',[_c('manifestacao',{attrs:{"tipo":op.tipo,"chNFe":props.item.chNFe}})],1),_c('v-list-tile-title',[_vm._v(_vm._s(_vm.tipos[op.tipo].description))])],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","small":"","color":"primary"},on:{"click":function($event){props.item.menuNFe = false}}},[_vm._v("Fechar")])],1)],1)],1)],1)])])]}}]),model:{value:(_vm.complex.selected),callback:function ($$v) {_vm.$set(_vm.complex, "selected", $$v)},expression:"complex.selected"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogCiencia),callback:function ($$v) {_vm.dialogCiencia=$$v},expression:"dialogCiencia"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Manifestação de NF'e (Ciência)")]),_c('v-card-text',[_vm._v("Deseja manifestar a ciência da operação?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","flat":""},on:{"click":function($event){_vm.dialogCiencia = false}}},[_vm._v("Não")]),_c('v-btn',{attrs:{"color":"primary darken-1","flat":""},on:{"click":function($event){return _vm.ciencia()}}},[_vm._v("Sim")])],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialogProcessando),callback:function ($$v) {_vm.dialogProcessando=$$v},expression:"dialogProcessando"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"pt-3"},[_vm._v(" Aguarde o Download do XML "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }