<template>
  <v-dialog
    v-model="dialogView"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          outline
          icon
          fab
          dark
          color="primary"
          small
          v-on="on"
          @click="montar()"
        >
          <v-icon>picture_as_pdf</v-icon>
        </v-btn>
      </template>
      <span>Ver Manifesto</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="atualizarMDF">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline"
          >Visualizar DAMFE
          <span v-if="mdf.TxChAcessoMDFe">vinculado a NFE - {{ mdf.NrNf }}</span>
          <!-- <span v-if="this.$store.state.statusNfeRetorno.xMotivo">
            - {{ $store.state.statusNfeRetorno.xMotivo }}</span
          > -->
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <iframe :src="url" width="100%" height="587" v-if="exibirPDF"></iframe>
    </v-card>
  </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
export default {
  props: {
    mdf: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialog: false,
      modalDataEntrada: false,
      modalDataValidade: false,
      dialogView: false,
      exibirPDF: false,
      valor: 16,
      fileName: "",
      url: "",
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      mrtValue: "",
      lastModifiedDate: "",
      loading: false,
      status: "",
      consultar: true,
    };
  },
  components: { pdf },
  methods: {
    montar() {
      let url = "";
      // this.dialogView = true;
      this.$store.state.statusNfeRetorno.xMotivo = "";
      this.consultar = false;
      // console.log(this.mdf);
      if (this.mdf.NrMDF) {
        url = this.$store.state.urlBase + "v1/sefaz/mdfe64/" + this.mdf.NrMDF;
      }
      /* else if (this.item.chCTe) {
        url =
          this.$store.state.urlBase +
          "v1/contabilidade/dacteByChave64/" +
          this.item.chCTe;

        let urlConsulta =
          this.$store.state.urlBaseWeb + "sefaz/CTeConsultaProtocolo/" + this.item.chCTe;
        this.$http.get(urlConsulta).then((response) => {
          this.$store.state.statusNfeRetorno = response.data;
        });
        this.$http
          .get(
            this.$store.state.urlBase + "v1/contabilidade/getObjCTe/" + this.item.chCTe
          )
          .then((response) => {
            if (response.data) {
              this.cte = response.data;
              // console.log(this.cte);
            }
          });
      }
      */
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http.get(url).then((response) => {
        this.url = "data:application/pdf;base64," + response.data;
        this.exibirPDF = true;
        this.consultar = true;
        this.dialogView = true;
        this.dialog = true;
        // console.log(this.url);
      });

      // this.consultar = false;
    },
    atualizarMDF() {
      this.url = "";
      this.exibirPDF = false;
      this.dialogView = false;
      this.consultar = true;
      // this.$store.state.statusNfeRetorno.xMotivo = "";
    },
  },
  updated() {
    // if (this.consultar) {
    //   this.montarDacteLocal();
    // }
    // console.log(this.dialog);
  },

  mounted() {},
};
</script>
