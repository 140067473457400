<template>
  <v-dialog
    v-model="$store.state.dialogViewMTR"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <!-- <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Fornecedor
        </v-btn> -->
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="atualizar()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline"
          >Visualizar MTR - {{ $store.state.mtr.NrNf }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <iframe :src="url" width="100%" height="587" v-if="exibirPDF"></iframe>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      valor: 16,
      fileName: "",
      url: "",
      exibirPDF: false,
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      mrtValue: "",
      lastModifiedDate: "",
      loading: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    atualizar() {
      this.$store.state.mtr = {};
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      // this.$store.state.dialogMTR = false
      // window.getApp.$emit("APP_RECORD_UPDATED");
      this.$store.commit("fecharModalViewMTR");
    },
    montarDanfeLocal(NrNf) {
      // console.log(NrNf);
      if (NrNf) {
        this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
        this.$http
          .get(this.$store.state.urlBase + "v1/sefaz/mtr64/" + NrNf)
          .then((response) => {
            if (response.data.error) {
              toastr.error(response.data.error, "Varejão da Sucata");
            } else {
              this.url = "data:application/pdf;base64," + response.data;
              this.exibirPDF = true;
              this.$store.state.mtr.file = response.data;
              this.$store.state.mtr.exibirPDF = true;
            }
          })
          .catch(function (err) {
            console.log(err);
            // toastr.error("MTR não vinculado à Nota Fiscal de Saída", "Varejão da Sucata");
            // this.exibirPDF = false;
            // this.$store.state.mtr = {};
            // this.$store.commit("fecharModalViewMTR");
          })
          .finally(function () {
            // alert("Deu certo");
          });
      }
    },
  },
  updated() {
    // window.getApp.$emit("APP_GET_mtr", this.$store.state.danfe.NrNf);
    this.montarDanfeLocal(this.$store.state.mtr.NrNf);
    //console.log(this);
    // setTimeout(() => {
    //   this.montarDanfe();
    // }, 1);
  },
  mounted() {},
};
</script>
