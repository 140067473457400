<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          outline
          icon
          fab
          dark
          color="primary"
          small
          v-on="on"
          @click.native="montarDanfeLocal()"
        >
          <v-icon>picture_as_pdf</v-icon>
        </v-btn>
      </template>
      <span>{{ textoBotao }}</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="atualizar()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Visualizar DANFE - {{ chave }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar dark dense color="primary">
        <v-toolbar-title class="subheading"
          >Status da Nota Fiscal
          <span v-if="statusNfeRetorno.xMotivo">
            : {{ statusNfeRetorno.xMotivo }} - (Cód. Retorno :
            {{ statusNfeRetorno.cStat }})
          </span></v-toolbar-title
        ></v-toolbar
      >
      <iframe :src="url" width="100%" height="587" v-if="exibirPDF"></iframe>
    </v-card>
  </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
import toastr from "toastr";
export default {
  props: {
    item: {},
    textoBotao: String,
    usarChave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      exibirPDF: false,
      valor: 16,
      fileName: "",
      url: "",
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      mrtValue: "",
      lastModifiedDate: "",
      loading: false,
      statusNfeRetorno: {
        xMotivo: "",
      },
    };
  },
  components: { pdf },
  computed: {
    chave() {
      // console.log(this.item);
      let chave = "";
      if (this.item.TxChAcessoNfe) {
        chave = this.item.TxChAcessoNfe;
      } else if (this.item.saida) {
        chave = this.item.saida.TxChAcessoNfe;
      } else if (this.item.chNFe) {
        chave = this.item.chNFe;
      }
      return chave;
    },
  },
  methods: {
    montarDanfeLocal() {
      let url = "";

      if (this.item.NrNf && this.usarChave === false) {
        let NrNF = parseInt(this.item.NrNf);
        url = this.$store.state.urlBase + "v1/sefaz/Danfe64/" + NrNF;
      } else if (this.item.saida && this.item.saida.NrNf && this.usarChave === false) {
        this.item.TxChAcessoNfe = this.item.saida.TxChAcessoNfe;
        url = this.$store.state.urlBase + "v1/sefaz/Danfe64/" + this.item.saida.NrNf;
      } else if (this.usarChave) {
        this.item.TxChAcessoNfe = this.item.chNFe;
        // console.log(this.item);
        url =
          this.$store.state.urlBase +
          "v1/sefaz/Danfe64ByChave/" +
          this.item.TxChAcessoNfe;
        // console.log(this.item);
      }

      this.statusNfeRetorno.xMotivo = "";
      let urlConsulta =
        this.$store.state.urlBaseWeb +
        "sefaz/NfeConsultaProtocolo/" +
        this.item.TxChAcessoNfe;
      this.$http.get(urlConsulta).then((response) => {
        this.statusNfeRetorno = response.data;
      });

      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http.get(url).then((response) => {
        if (response.data.error) {
          toastr.error(response.data.error, "Danfe");
          this.atualizar();
        } else {
          this.url = "data:application/pdf;base64," + response.data;
          this.exibirPDF = true;
        }
      });
    },

    atualizar() {
      this.$store.state.danfe = {};
      this.url = "";
      this.exibirPDF = false;
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      this.dialog = false;
      // window.getApp.$emit("APP_RECORD_UPDATED");
      // this.$store.commit("fecharModalDanfe");
    },
  },
  updated() {
    // window.getApp.$emit("APP_GET_DANFE", this.$store.state.danfe.NrNf);
    // this.montarDanfeLocal();
    //console.log(this);
    // setTimeout(() => {
    //   this.montarDanfe();
    // }, 1);
  },

  mounted() {},
};
</script>
