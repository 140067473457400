<template>
    <v-dialog v-model="$store.state.dialogSaida" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Nova Saída
        </v-btn>
        <v-card>
            <v-card-title>
                <span class="headline">Saída</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-autocomplete
                                :disabled="typeof($store.state.saida.id) !== 'undefined'"
                                v-model="$store.state.saida.CdFuncionarioRequisitante"
                                :items="$store.getters.funcionarios"
                                item-text="NmFuncionario"
                                item-value="CdFuncionario"
                                label="Funcionário *"
                                required
                                ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-autocomplete
                                :disabled="typeof($store.state.saida.id) !== 'undefined'"
                                v-model="$store.state.saida.item_id"
                                :items="$store.getters.itens"
                                item-text="descricao"
                                item-value="id"
                                label="Item *"
                                required
                                ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field 
                            :disabled="typeof($store.state.saida.id) !== 'undefined'"
                            label="Motivo *" v-model="$store.state.saida.motivo"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-select
                                :disabled="typeof($store.state.saida.id) !== 'undefined'"
                                v-model="$store.state.saida.CdUnidade"
                                :items="$store.state.unidades"
                                :rules="[]"
                                label="Unidade *"
                                required
                                ></v-select>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field 
                            :disabled="typeof($store.state.saida.id) !== 'undefined'"
                            label="Quantidade *" required v-model="$store.state.saida.quantidade"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-dialog
                                :disabled="typeof($store.state.entrada.id) !== 'undefined'"
                                ref="dialogDataEntrega"
                                v-model="modalDataEntrega"
                                :return-value.sync="$store.state.saida.dtEntrega"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <v-text-field
                                    :disabled="typeof($store.state.saida.id) !== 'undefined'"
                                    slot="activator"
                                    v-model="dtEntregaFormatted"
                                    label="Data de Entrega"
                                    prepend-icon="event"
                                    readonly
                                    ></v-text-field>
                                <v-date-picker 
                                    v-model="$store.state.saida.dtEntrega" 
                                    locale="pt-br"
                                    scrollable 
                                    @change="$refs.dialogDataEntrega.save($store.state.saida.dtEntrega)"
                                    @input="dtEntregaFormatted=formatDate($store.state.saida.dtEntrega) " >
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="modalDataEntrega = false">Cancelar</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <v-checkbox
                            v-model="$store.state.saida.paraTerceirizado"
                            label="Para Terceirizados"
                        ></v-checkbox>
                        <v-flex xs6 sm6 md6>
                            <v-text-field label="Valor da Última Entrada"  v-model="$store.state.saida.valorUltEntrada"   disabled v-show="typeof($store.state.saida.id) !== 'undefined'"></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-text-field 
                            :disabled="typeof($store.state.saida.id) !== 'undefined'"
                            v-show="$store.state.saida.id"
                            label="Usuário *" v-model="$store.state.saida.CdUsuario"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <small>* campo obrigatório</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Cancelar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.getters.saida.id">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
// import 'moment/locale/pt-br'
// moment.locale('pt-BR')
export default {
    name: 'Saida',
    data () {
        return {
            modalDataEntrega:false,
            dialog: false,
            valor: 16
        }
    },
    computed: {
        dtEntregaFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.saida.dtEntrega) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.saida.dtEntrega).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.saida.dtEntrega = this.parseDate(date)
                return date
            }
        },
    },
    methods : {
        salvar (){
             let saida = this.$store.state.saida
             this.$http.post(this.$store.state.urlBase+'almoxarifado/saida',saida)
                 .then(response => {
                    if(typeof response.data.errors !== 'undefined'){
                         //Erros de Validação
                         for (var error in response.data.errors) {
                             toastr.error(response.data.errors[error],'Saída de Itens')
                         }
                    }else{
                        this.atualizar()
                    }
                   
             })
        },
        atualizar () {
            this.$store.state.saida = {}
            window.getApp.$emit('APP_SAIDA_UPDATED')
            this.$store.commit('fecharModalSaida')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toFixed(2)
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
        }
  },
    mounted () {
        
    }
}
</script>
