<template>
  <v-dialog
    v-model="$store.state.dialogMTR"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <!-- <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Fornecedor
        </v-btn> -->
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="$store.state.dialogMTR = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Enviar MTR</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field
                label="Selecione o arquivo MTR"
                @click="onPickFile"
                v-model="fileName"
                prepend-icon="attach_file"
              ></v-text-field>
              <!-- Hidden -->
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="*/*"
                @change="onFilePicked"
              />
              <!-- <v-btn
                color="primary"
                @click.stop="onUploadSelectedFileClick"
                :loading="loading"
                >UPLOAD</v-btn
              >--></v-flex
            >
          </v-layout>
        </v-container>
        <!-- <small>* campo obrigatório</small> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click.stop="onUploadSelectedFileClick"
          :loading="loading"
          >Enviar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      valor: 16,
      fileName: "",
      url: "",
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      mrtValue: "",
      lastModifiedDate: "",
      loading: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        // Check validity of file
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.url = fr.result;
          this.fileObject = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.fileName = "";
        this.fileObject = null;
        this.url = "";
      }
    },
    onUploadSelectedFileClick() {
      toastr.success("Enviando arquivo...", "Varejão da Sucata");
      this.loading = true;
      this.fileObject.url = this.url;
      this.fileObject.TxChAcessoNfe = this.$store.state.mtr.TxChAcessoNfe;

      // A file is not chosen!
      if (!this.fileObject) {
        alert("No file!!");
      }
      // DO YOUR JOB HERE with fileObjectToUpload
      // https://developer.mozilla.org/en-US/docs/Web/API/File/File
      this.name = this.fileObject.name;
      this.size = this.fileObject.size;
      this.type = this.fileObject.type;
      this.lastModifiedDate = this.fileObject.lastModifiedDate;
      // DO YOUR JOB HERE with fileObjectToUpload
      let fileObjectToUpload = {};
      fileObjectToUpload.url = this.url;
      fileObjectToUpload.TxChAcessoNfe = this.$store.state.mtr.TxChAcessoNfe;
      fileObjectToUpload.name = this.name;
      fileObjectToUpload.size = this.size;
      fileObjectToUpload.type = this.type;
      fileObjectToUpload.lastModifiedDate = this.lastModifiedDate;
      //console.log(fileObjectToUpload);
      this.$http
        .post(this.$store.state.urlBase + "v1/comercial/NfSaidas/AnexarMTR", {
          fileObjectToUpload,
        })
        .then((response) => {
          if (response.data.error) {
            toastr.error(response.data.error, "Varejão da Sucata");
          } else {
            toastr.success("Arquivo Vinculado com Sucesso!", "Varejão da Sucata");
            this.atualizar();
          }
        });

      this.loading = false;
    },
    enviar() {
      // let entrada = this.$store.state.entrada
    },
    atualizar() {
      this.$store.state.mtr = {};
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      // this.$store.state.dialogMTR = false
      window.getApp.$emit("APP_RECORD_UPDATED");
      this.$store.commit("fecharModalMTR");
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },

    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
  mounted() {},
};
</script>
