<template>
    <v-dialog v-model="$store.state.dialogFornecedor" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <!-- <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Fornecedor
        </v-btn> -->
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon flat dark @click="$store.state.dialogFornecedor = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title class="headline">Fornecedor de Sucata</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-toolbar-items>
                    <v-btn dark flat @click="dialog = false">Save</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <!-- <v-card-title>
                <span class="headline">Fornecedor de Sucata</span>
            </v-card-title> -->
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-text-field 
                            :disabled="typeof($store.state.fornecedor.CdFornec) !== 'undefined'"
                            label="Nome Completo *" v-model="$store.state.fornecedor.NmFornec"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field 
                            :disabled="typeof($store.state.fornecedor.CdFornec) !== 'undefined'"
                            label="CPF/CPNJ *" v-model="$store.state.fornecedor.CdCpfCgcFormatado"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-autocomplete
                                :disabled="true"
                                v-model="$store.state.fornecedor.CdComprador"
                                :items="$store.state.compradores"
                                item-text="nmComprador"
                                item-value="CdComprador"
                                label="Comprador *"
                                required
                                ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <preco-padrao-sucata v-if="$store.state.fornecedor.CdComprador !== '0'" class="hidden-sm-and-down"></preco-padrao-sucata>
                        </v-flex>
                    </v-layout>
                    
                </v-container>
                <!-- <small>* campo obrigatório</small> -->
            </v-card-text>
            <preco-padrao-sucata v-if="$store.state.fornecedor.CdComprador !== '0'" class="hidden-md-and-up"></preco-padrao-sucata>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
                <!-- <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.getters.entrada.id">Salvar</v-btn> -->
            </v-card-actions>
        </v-card>
        
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
import PrecoPadraoSucata from '@/components/comercial/PrecoPadraoSucata'
export default {
    data () {
        return {
            modalDataEntrada:false,
            modalDataValidade:false,
            dialog: false,
            valor: 16
        }
    },
    components: {
        PrecoPadraoSucata
    },
    computed: {
        dtEntradaFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.entrada.dtEntrada) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.entrada.dtEntrada).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.entrada.dtEntrada = this.parseDate(date)
                return date
            }
        },
        dtValidadeFormatted: {
            // getter
            get: function () {
                if(typeof(this.$store.state.entrada.dtValidade) !== 'undefined'){
                    return this.formatDate(new Date(this.$store.state.entrada.dtValidade).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.$store.state.entrada.dtValidade = this.parseDate(date)
                return date
            }     
        }
    },
    methods : {
        salvar (){
            let entrada = this.$store.state.entrada
            // this.$http.post(this.$store.state.urlBase+'almoxarifado/entrada',entrada)
            //     .then(response => {
            //        if(typeof response.data.errors !== 'undefined'){
            //             //Erros de Validação
            //             for (var error in response.data.errors) {
            //                 toastr.error(response.data.errors[error],'Entrada de Itens')
            //             }
            //        }else{
            //            window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
            //            this.atualizar()
            //        }
                   
            // })
        },
        atualizar () {
            this.$store.state.fornecedor = {}
            // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
            // this.$store.state.dialogFornecedor = false
            this.$store.commit('fecharModalFornecedor')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toFixed(2)
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt)
        }
  },
    mounted () {
        
    }
}
</script>
