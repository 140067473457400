<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn depressed outline icon fab dark color="primary" small v-on="on">
          <v-icon>{{ icone }}</v-icon>
        </v-btn>
      </template>
      <span>{{ titulo }}</span>
    </v-tooltip>
    <v-card>
      <v-card-title class="headline">Manifestação de NF'e ({{ subtitulo }})</v-card-title>
      <v-card-text>Deseja manifestar a {{ subtitulo }} da operação?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click="dialog = false">Não</v-btn>
        <v-btn color="primary darken-1" flat @click="submeter()">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      dialog: false,
      tipos: [
        {
          description: "Sem Manifestação do Destinatário",
          shortDescription: null,
          service: null,
          tipo: 0,
          icon: "",
        },
        {
          description: "Confirmação da Operação",
          shortDescription: "Confirmação",
          service: "confirmacao",
          icon: "thumb_up_alt",
          tipo: 1,
        },
        {
          description: "Desconhecimento da Operação",
          shortDescription: "Desconhecimento",
          service: "desconhecimento",
          icon: "thumb_down_alt",
          tipo: 2,
        },
        {
          description: "Operação não realizada",
          shortDescription: "Não realização",
          service: "naorealizada",
          icon: "block",
          tipo: 3,
        },
        {
          description: "Ciência da Operação",
          shortDescription: "Ciência",
          service: "ciencia",
          icon: "sports_score",
          tipo: 4,
        },
      ],
    };
  },
  props: {
    tipo: Number,
    chNFe: String,
  },
  computed: {
    titulo() {
      return this.tipos[this.tipo].description;
    },
    subtitulo() {
      return this.tipos[this.tipo].shortDescription;
    },
    icone() {
      return this.tipos[this.tipo].icon;
    },
  },
  methods: {
    abrirManifestacao(tipo) {
      toastr.success(tipo, "Varejão da Sucata");
    },
    getInstance() {
      return this;
    },
    submeter() {
      toastr.success(this.titulo + "...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(
          this.$store.state.urlBase +
            "v1/sefaz/" +
            this.tipos[this.tipo].service +
            "/" +
            this.chNFe
        )
        .then((response) => {
          if (response.data.erro) {
            toastr.error(
              response.data.erro,
              "Nota Fiscal " + this.chNFe + " " + this.titulo
            );
            this.dialog = true;
          } else {
            toastr.success(this.titulo + " manifestada!", "Varejão da Sucata");
            window.getApp.$emit("APP_RECORD_UPDATED");
          }
        });
    },
    ucFirst(string) {
      if (!string) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
