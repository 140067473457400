<template>
    <v-dialog v-model="$store.state.dialogCliente" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <!-- <v-btn slot="activator" color="primary" dark>
            <v-icon left>add_circle_outline</v-icon> 
            Novo Fornecedor
        </v-btn> -->
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon flat dark @click="$store.state.dialogCliente = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title class="headline">Cliente</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-toolbar-items>
                    <v-btn dark flat @click="dialog = false">Save</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <!-- <v-card-title>
                <span class="headline">Fornecedor de Sucata</span>
            </v-card-title> -->
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-text-field 
                            :disabled="typeof($store.state.cliente.CdCpfCgc) !== 'undefined'"
                            label="Nome Cliente *" v-model="$store.state.cliente.NmCliente"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field 
                            :disabled="typeof($store.state.cliente.CdCpfCgc) !== 'undefined'"
                            label="Razão Social *" v-model="$store.state.cliente.NmRazaoSocial"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field 
                            :disabled="typeof($store.state.cliente.CdCpfCgc) !== 'undefined'"
                            label="CPF/CPNJ *" v-model="$store.state.cliente.CdCpfCgcFormatado"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <preco-padrao-sucata v-if="$store.state.cliente.CdCpfCgc !== '0'" class="hidden-sm-and-down"></preco-padrao-sucata>
                        </v-flex>
                    </v-layout>
                    
                </v-container>
                <!-- <small>* campo obrigatório</small> -->
            </v-card-text>
            <preco-padrao-sucata v-if="$store.state.cliente.CdCpfCgc !== '0'" class="hidden-md-and-up"></preco-padrao-sucata>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Sair</v-btn>
                <!-- <v-btn color="blue darken-1" flat @click.native="salvar()" v-if="!$store.getters.entrada.id">Salvar</v-btn> -->
            </v-card-actions>
        </v-card>
        
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
import PrecoPadraoSucata from '@/components/comercial/PrecoPadraoSucataSaida'
export default {
    data () {
        return {
            modalDataEntrada:false,
            modalDataValidade:false,
            dialog: false,
            valor: 16
        }
    },
    components: {
        PrecoPadraoSucata
    },
    computed: {
        
    },
    methods : {
        salvar (){
            // let entrada = this.$store.state.entrada
        },
        atualizar () {
            this.$store.state.cliente = {}
            // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
            // this.$store.state.dialogCliente = false
            this.$store.commit('fecharModalCliente')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toFixed(2)
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt)
        }
  },
    mounted () {
        
    }
}
</script>
