<template>
  <v-card>
    <v-toolbar card dense dark color="primary">
      <v-toolbar-title class="px-5"
        ><h4 class="px-0">
          Listagem de Manifestos Associados a esta NF-e
        </h4></v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0 px-5">
      <v-list three-line v-if="$store.state.mfesVinculados.length > 0">
        <template v-for="(item, index) in $store.state.mfesVinculados">
          <v-list-tile :key="item.title" avatar ripple>
            <v-list-tile-content class="py-2">
              <v-list-tile-title class="text--primary pt-1"
                ><b>Número:</b> {{ item.NrMDF }} - <b>Chave:</b>
                {{ item.TxChAcessoMDFe }} - <b>Status:</b>
                {{ item.CdRetorno }}
                <span v-if="item.CdRetorno === '100'"> - Autorizado</span>
                <span v-else> - {{ item.objEncerramento.infEvento.xEvento }}</span>
              </v-list-tile-title>
              <v-list-tile-sub-title class="text--primary pt-1"
                >Data Emissão:
                {{ item.DtEmissao | formatDate("DD/MM/YYYY") }}
                <span v-if="item.CdRetorno === '101'">
                  - Data de Cancelamento:
                  {{ item.DtEntrega | formatDate("DD/MM/YYYY HH:mm:ss") }}
                </span>
                <span v-if="item.CdRetorno === '132'">
                  - Data de Encerramento:
                  {{ item.DtEntrega | formatDate("DD/MM/YYYY HH:mm:ss") }}
                </span>
              </v-list-tile-sub-title>
              <v-list-tile-sub-title class="text--primary pt-1"
                >Placa: {{ item.NmPlaca }}</v-list-tile-sub-title
              >
            </v-list-tile-content>

            <v-list-tile-action>
              <v-menu
                v-model="item.menuMDFe"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark v-on="on" fab small flat
                    ><v-icon dark>more_vert</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-list>
                    <v-subheader>Funções MDF-e</v-subheader>
                    <template v-if="item.CdRetorno === '100'">
                      <v-list-tile>
                        <v-list-tile-action @click="encerrar(item)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                depressed
                                outline
                                icon
                                fab
                                dark
                                color="primary"
                                small
                                v-on="on"
                                @click.native="encerrar(item)"
                              >
                                <v-icon>mdi-truck-check-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Sinalizar como Entregue</span>
                          </v-tooltip>
                        </v-list-tile-action>
                        <v-list-tile-title>Sinalizar como Entregue</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-action @click="cancelar(item)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                depressed
                                outline
                                icon
                                fab
                                dark
                                color="primary"
                                small
                                v-on="on"
                                @click.native="cancelar(item)"
                              >
                                <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Cancelar</span>
                          </v-tooltip>
                        </v-list-tile-action>
                        <v-list-tile-title>Cancelar</v-list-tile-title>
                      </v-list-tile>
                    </template>

                    <v-list-tile>
                      <v-list-tile-action>
                        <mdfe-view :mdf="item" v-if="item"></mdfe-view>
                      </v-list-tile-action>
                      <v-list-tile-title>Ver Manifesto</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-action @click="consultar(item)">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              depressed
                              outline
                              icon
                              fab
                              dark
                              color="primary"
                              small
                              v-on="on"
                              @click.native="consultar(item)"
                            >
                              <v-icon>search</v-icon>
                            </v-btn>
                          </template>
                          <span>Consultar</span>
                        </v-tooltip>
                      </v-list-tile-action>
                      <v-list-tile-title>Consultar</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn flat @click="item.menuNFe = false" small color="primary"
                      >Fechar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider
            v-if="index + 1 < $store.state.mfesVinculados.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import * as moment from "moment";
import MdfeView from "@/components/contabilidade/MDFeView";
export default {
  components: {
    MdfeView,
  },
  data() {
    return {
      selected: [2],
      statusMDFe: {
        101: "Cancelamento pelo emitente",
        132: "Encerramento",
      },
    };
  },
  computed: {
    randomColor() {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    },
  },
  methods: {
    consultar(item) {
      let url =
        "https://dfe-portal.svrs.rs.gov.br/MDFE/QRCODE?chMDFe=" +
        item.TxChAcessoMDFe +
        "&tpAmb=1";
      window.open(url);
    },
    getColorByStatus(status) {
      return this.colors[status];
    },
    cancelar(item) {
      this.$http
        .get(this.$store.state.urlBaseWeb + "sefaz/cancelarMDFe/" + item.NrMDFe)
        .then((response) => {
          if (response.data.error) {
            // this.dialog = false;
            toastr.error(response.data.error, "Varejão da Sucata");
          } else {
            this.dialog = false;
            toastr.success("MDF cancelado com Sucesso!", "Varejão da Sucata");
            this.atualizar(item);
          }
        });
    },
    encerrar(item) {
      this.$http
        .get(this.$store.state.urlBaseWeb + "sefaz/encerrarMDFe/" + item.NrMDFe)
        .then((response) => {
          if (response.data.error) {
            // this.dialog = false;
            toastr.error(response.data.error, "Varejão da Sucata");
          } else {
            this.dialog = false;
            toastr.success("MDF encerrado com Sucesso!", "Varejão da Sucata");
            this.atualizar(item);
          }
        });
    },
    atualizar(item) {
      //Buscar MDFes Vinculados
      this.$store.state.mfesVinculados = [];
      this.$http
        .get(this.$store.state.urlBase + "v1/sefaz/mdfes/" + item.NrNf)
        .then((response) => {
          if (response.data) {
            this.$store.state.mfesVinculados = response.data;
          }
        });
    },
  },
  mounted() {
    // window.getApp.$emit('APP_PRECO_PADRAO_FORNECEDOR_GET')
    //  console.log(this.$store.state.precoPadrao)
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toLocaleString("pt-BR");
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
</script>
