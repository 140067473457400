<template>
    <v-dialog v-model="$store.state.dialogRelatorioSaida" persistent max-width="500px">
        <v-btn slot="activator" color="primary" dark>
            <v-icon left>picture_as_pdf</v-icon> 
            Relatório
        </v-btn>
        <v-card>
            <v-card-title>
                <span class="headline">Relatório de Saídas por Categoria (Agrupado por Funcionário)</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-select
                                v-model="relatorio.Categoria_id"
                                :items="$store.state.categorias"
                                label="Categoria *"
                                required
                                item-text="descricao"
                                item-value="id"
                                ></v-select>
                            <!-- <v-text-field label= "Categoria *" required v-model="valor"></v-text-field> -->
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-dialog
                                ref="dialogDtIni"
                                v-model="modalDtIni"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <v-text-field
                                    slot="activator"
                                    v-model="dtIniFormatted"
                                    label="Data Inicial"
                                    prepend-icon="event"
                                    readonly
                                    ></v-text-field>
                                <v-date-picker 
                                    v-model="relatorio.DtIni" 
                                    locale="pt-br"
                                    scrollable 
                                    @change="$refs.dialogDtIni.save(relatorio.DtIni)"
                                    @input="dtIniFormatted=formatDate(relatorio.DtIni) " >
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="modalDtIni = false">Cancelar</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-dialog
                                ref="dialogDtFin"
                                v-model="modalDtFin"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <v-text-field
                                    slot="activator"
                                    v-model="dtFinFormatted"
                                    label="Data Final"
                                    prepend-icon="event"
                                    readonly
                                    ></v-text-field>
                                <v-date-picker 
                                    v-model="relatorio.DtFin" 
                                    locale="pt-br"
                                    :min="relatorio.DtIni"
                                    scrollable 
                                    @change="$refs.dialogDtFin.save(relatorio.DtFin)"
                                    @input="dtFinFormatted=formatDate(relatorio.DtFin) " >
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="modalDtFin = false">Cancelar</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <v-checkbox
                        v-model="relatorio.ParaTerceirizados"
                        label="Incluir para Terceirizados"
                        ></v-checkbox>
                    </v-layout>
                </v-container>
                <small>* campo obrigatório</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="atualizar()">Cancelar</v-btn>
                <v-btn color="blue darken-1" flat @click.native="gerar()">Gerar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import toastr from 'toastr'
export default {
    name: 'RelatorioSaidasCategoriaFuncionario',
    data () {
        return {
            modalDtIni:false,
            modalDtFin:false,
            dialog: false,
            relatorio: {}
        }
    },
    computed: {
        dtIniFormatted: {
            // getter
            get: function () {
                if(typeof(this.relatorio.DtIni) !== 'undefined'){
                    return this.formatDate(new Date(this.relatorio.DtIni).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.relatorio.DtIni = this.parseDate(date)
                return date
            }
        },
        dtFinFormatted: {
            // getter
            get: function () {
                if(typeof(this.relatorio.DtFin) !== 'undefined'){
                    return this.formatDate(new Date(this.relatorio.DtFin).toISOString().substr(0, 10))
                }
                
            },
            // setter
            set: function (date) {
                this.relatorio.DtFin = this.parseDate(date)
                return date
            }
        },
    },
    methods : {
        gerar (){
            let parametros = this.relatorio
            // console.log(parametros)
            this.$http.post(this.$store.state.urlBase+'almoxarifado/saidaPorCategoriaFuncionario',parametros,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf'
                }
            }).then(response => {
                        if(typeof response.data.errors !== 'undefined'){
                            //Erros de Validação
                            for (var error in response.data.errors) {
                                toastr.error(response.data.errors[error],'Relatório de Saídas')
                            }
                        }else{
                        // console.log(response)
                            var blob = new Blob([response.data], {
                                    type: 'application/pdf'
                            });
                            var url = window.URL.createObjectURL(blob)
                            window.open(url);
                            this.atualizar ();
                            window.getApp.$emit('APP_SAIDA_UPDATED')
                        }
                    
             })
        },
        atualizar () {
            // this.$store.state.saida = {}
            // window.getApp.$emit('APP_SAIDA_UPDATED')
            // window.getApp.$emit('APP_CATEGORIAS_GET')
            this.$store.commit('fecharModalRelatorioSaida')
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
    filters: {
        formatNumber(value){
            let number = parseFloat(value)
            return number.toFixed(2)
        },

        formatDate(value, fmt = "D MMM YYYY") {
            return value == null
                ? ""
                : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
        }
  },
    mounted () {
        
    },
    created () {
      window.getApp.$emit('APP_CATEGORIAS_GET');
  },
}
</script>
