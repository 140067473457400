<template>
  <v-card>
    <v-toolbar card dense color="transparent">
      <v-toolbar-title><h4>Preço padrão de sucata</h4></v-toolbar-title>
      <v-spacer></v-spacer>
      <preco-padrao-modal></preco-padrao-modal>
      <!-- <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>       -->
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
         <v-list three-line> 
          <template v-for="(item, index) in $store.state.precoPadraoSaida">
            <v-list-tile
              :key="item.title"
              avatar
              ripple
              
            >
              <v-list-tile-content class="py-2">
                <v-list-tile-title class="text--primary pt-1"><b>Tipo de Sucata:</b> {{ item.tipo.NmTipoSucata }}</v-list-tile-title>
                <!-- <v-list-tile-sub-title class="text--primary pt-1">Usuário alteração: {{ item.CdUsuarioUltimaAlt }}</v-list-tile-sub-title> -->
                <v-list-tile-sub-title class="text--primary pt-1">Última alteração: {{ item.DtUltimaAlteracao | formatDate('DD/MM/YYYY HH:mm:ss') }}</v-list-tile-sub-title>
                <v-list-tile-sub-title class="text--primary pt-1">Data criação: {{ item.DtCriacao | formatDate('DD/MM/YYYY HH:mm:ss') }}</v-list-tile-sub-title>
              </v-list-tile-content>

              <v-list-tile-action>
                <v-list-tile-action-text>R$ {{ item.VlPrecoPadrao | formatNumber }}</v-list-tile-action-text>
              </v-list-tile-action>

            </v-list-tile>
            <v-divider
              v-if="index + 1 < $store.state.precoPadrao.length"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import items from '@/api/order'
import PrecoPadraoModal from '@/components/comercial/PrecoPadraoSaidaModal'
import * as moment from "moment"
export default {
  components: {
        PrecoPadraoModal
    },
  data () {
    return {
      selected: [2]
    };
  },
  computed: {
    randomColor () {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    },
  },
  methods: {
    getColorByStatus (status) {
      return this.colors[status];
    }
  },
  mounted(){
    // window.getApp.$emit('APP_PRECO_PADRAO_FORNECEDOR_GET')
    //  console.log(this.$store.state.precoPadrao)
  },
  filters: {
    formatNumber(value){
          let number = parseFloat(value)
          return number.toLocaleString('pt-BR')
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt)
    },
  }
}
</script>
