<template>
  <v-app id="login" class="primary">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="/img/1.jpg" alt="Varejão da Sucata" class="logo-empresa" />
                  <h1 class="flex my-4 primary--text text-xs-center nome-empresa">
                    Varejao da Sucata {{ model.CdLoja.text }}
                  </h1>
                </div>
                <v-form>
                  <v-select
                    v-model="model.CdLoja"
                    :hint="`${model.CdLoja.CdLoja}, ${model.CdLoja.text}`"
                    :items="lojas"
                    item-text="text"
                    item-value="CdLoja"
                    append-outer-icon="store"
                    menu-props="auto"
                    hide-details
                    label="Loja"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                  <v-text-field
                    append-icon="person"
                    name="login"
                    label="Login"
                    type="text"
                    v-model="model.username"
                  ></v-text-field>
                  <v-text-field
                    append-icon="lock"
                    name="password"
                    label="Senha"
                    id="password"
                    type="password"
                    v-model="model.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn block color="primary" @click="login" :loading="loading"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import toastr from "toastr";
export default {
  data: () => ({
    loading: false,
    model: {
      username: "",
      password: "",
      CdLoja: { CdLoja: "1", text: "Matriz" },
    },
    lojas: [
      { CdLoja: "1", text: "Matriz" },
      { CdLoja: "2", text: "Filial" },
    ],
  }),
  methods: {
    login() {
      this.loading = true;
      this.$http
        .post(this.$store.state.urlBase + "auth/login/" + this.model.CdLoja.CdLoja, {
          email: this.model.username,
          password: this.model.password,
        })
        .then((response) => {
          // console.log(response)
          if (response.data.token) {
            //Login com sucesso
            this.$session.start();
            this.$session.set("user", response.data);
            this.$store.state.user = response.data;
            this.$store.state.CdLoja = this.model.CdLoja.CdLoja;
            this.$http.defaults.headers.common["CdLoja"] = this.model.CdLoja.CdLoja;
            this.$store.commit("setUser", response.data);
            window.localStorage.setItem("user", response.data);
            window.user = response.data;
            window.getApp.$emit("APP_LOJA_GET");
            window.getApp.$emit("APP_SAIDAS_CLIENTE_GET");
            window.getApp.$emit("APP_LOGIN_SUCCESS");
            //Login Não Existe
          } else if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "Varejão da Sucata");
            }
            this.loading = false;
          }
          //Zerando o Formulário
          this.model.username = "";
          this.model.password = "";
          // // $('.form-login').show('slow')
          // this.$store.commit('loading',false)
        });
      // .catch(error => {
      //     console.log(error)
      //     this.$store.commit('loading',false)
      //     toastr.error('Falha de conexão com a API','Varejão da Sucata')
      // })
    },
  },
};
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.nome-empresa {
  line-height: 45px;
}
.logo-empresa {
}
</style>
