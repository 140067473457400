<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    transition="dialog-bottom-transition"
    fullscreen
    persistent
  >
    <v-tooltip top slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          outline
          icon
          fab
          dark
          color="primary"
          small
          v-on="on"
          @click.native="abrirEmissor()"
        >
          <v-icon>mdi-dump-truck</v-icon>
        </v-btn>
      </template>
      <span>Emitir MDF</span>
    </v-tooltip>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="atualizar()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Emissão de MTR </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form ref="formMDF" v-model="validMDF" lazy-validation>
          <v-container class="pt-5">
            <v-flex xs12>
              <span class="title mt-5 pt-5">Informações do Veículo</span>
            </v-flex>
            <v-layout wrap class="mt-2 mb-5">
              <v-flex xs12 md4>
                <v-text-field
                  v-model="mdf.veiculo.placa"
                  :counter="7"
                  label="Placa"
                  placeholder="AAA9999"
                  required
                  :rules="[(v) => !!v || 'Placa do veículo é um campo obrigatório!']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="mdf.veiculo.tara"
                  label="Tara"
                  hint="Preencher a tara do caminhão"
                  required
                  :rules="[(v) => !!v || 'Tara do veículo é um campo obrigatório!']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  :items="tpRod"
                  item-text="text"
                  item-value="cod"
                  label="Tipo de Rodado"
                  v-model="mdf.veiculo.tpRod"
                  :rules="[(v) => !!v || 'Tipo de Rodado é um campo obrigatório!']"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  :items="tpCar"
                  item-text="text"
                  item-value="cod"
                  label="Tipo de Carroceria"
                  v-model="mdf.veiculo.tpCar"
                  :rules="[(v) => !!v || 'Tipo de Carroceria é um campo obrigatório!']"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  :items="UF"
                  item-text="nome"
                  item-value="sigla"
                  label="UF"
                  hint="Informar a UF em que veículo está licenciado"
                  v-model="mdf.veiculo.UF"
                  :rules="[(v) => !!v || 'UF do veículo é um campo obrigatório!']"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-2 mb-5">
              <v-flex xs12>
                <span class="title mb-5">Informações do Condutor</span>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="mdf.veiculo.condutor.nome"
                  label="Nome"
                  hint="Informar o Nome do Condutor."
                  required
                  :rules="[(v) => !!v || 'Nome do Condutor é um campo obrigatório!']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="mdf.veiculo.condutor.CPF"
                  label="CPF"
                  hint="Informar o CPF do Condutor."
                  required
                  :rules="[(v) => !!v || 'CPF do Condutor é um campo obrigatório!']"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-2">
              <v-flex xs12>
                <v-btn :disabled="!validMDF" color="primary" @click="emitirMDF">
                  Emitir
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <template v-if="this.$store.state.mfesVinculados.length > 0">
        <list-mdfe></list-mdfe>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import ListMdfe from "@/components/contabilidade/ListMdfe";

export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  components: {
    ListMdfe,
  },
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      exibirPDF: false,
      valor: 16,
      fileName: "",
      url: "",
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      mrtValue: "",
      lastModifiedDate: "",
      loading: false,
      status: "",
      consultar: true,
      cte: {},
      validMDF: false,
      mdf: {
        veiculo: {
          condutor: {},
        },
      },
      tpCar: [
        { cod: "00", text: "não aplicável" },
        { cod: "01", text: "Aberta" },
        { cod: "02", text: "Fechada/Baú" },
        { cod: "03", text: "Granelera" },
        { cod: "04", text: "Porta Container" },
        { cod: "05", text: "Sider" },
      ],
      tpRod: [
        {
          cod: "01",
          text: "Truck",
        },
        {
          cod: "02",
          text: "Toco",
        },
        {
          cod: "03",
          text: "Cavalo Mecânico",
        },
        {
          cod: "04",
          text: "VAN",
        },
        {
          cod: "05",
          text: "Utilitário",
        },
        {
          cod: "06",
          text: "Outros",
        },
      ],
      UF: [
        { nome: "Acre", sigla: "AC" },
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Paraná", sigla: "PR" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Santa Catarina", sigla: "SC" },
        { nome: "São Paulo", sigla: "SP" },
        { nome: "Sergipe", sigla: "SE" },
        { nome: "Tocantins", sigla: "TO" },
      ],
    };
  },
  methods: {
    abrirEmissor() {
      //Buscar MDFes Vinculados
      this.$store.state.mfesVinculados = [];
      this.$http
        .get(this.$store.state.urlBase + "v1/sefaz/mdfes/" + this.item.NrNf)
        .then((response) => {
          if (response.data) {
            this.$store.state.mfesVinculados = response.data;
          }
        });
    },
    emitirMDF() {
      if (this.$refs.formMDF.validate()) {
        let mdf = this.mdf;
        this.$http
          .post(this.$store.state.urlBaseWeb + "sefaz/mdfe/" + this.item.NrNf, {
            mdf,
          })
          .then((response) => {
            if (response.data.error) {
              // this.dialog = false;
              toastr.error(response.data.error, "Varejão da Sucata");
            } else {
              this.dialog = false;
              toastr.success("MDF enviado com Sucesso!", "Varejão da Sucata");
              this.atualizar();
            }
          });
      } else {
        toastr.error("Todos os campos são obrigatórios!", "Varejão da Sucata");
      }
    },
    atualizar() {
      this.mdf = {
        veiculo: {
          condutor: {},
        },
      };
      this.$store.state.mfesVinculados = [];
      this.dialog = false;
      window.getApp.$emit("APP_RECORD_UPDATED");
    },
  },
  updated() {
    // if (this.consultar) {
    //   this.montarDacteLocal();
    // }
    // console.log(this.dialog);
  },

  mounted() {},
};
</script>
