var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm12":""}},[_c('h3',[_vm._v("Notas Fiscais de Saída (Sem exibir Notas Complementares)")])]),_c('v-flex',{attrs:{"lg12":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('mtr-modal'),_c('mtr-view')],1),_c('v-toolbar',{attrs:{"card":"","color":"white"}},[_c('v-text-field',{attrs:{"flat":"","solo":"","prepend-icon":"search","placeholder":"Digite algo","hide-details":"","classeantiga":"hidden-sm-and-down"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("filter_list")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.complex.headers,"search":_vm.search,"items":_vm.complex.items,"rows-per-page-items":[10, 25, 50, { text: 'Todos', value: -1 }],"rows-per-page-text":"Linhas por página","item-key":"name","pagination":_vm.complex.pagination},on:{"update:pagination":function($event){return _vm.$set(_vm.complex, "pagination", $event)}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.NrNf))]),_c('td',[_vm._v(_vm._s(props.item.NmCliente))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(props.item.DtSaida,"DD/MM/Y")))]),_c('td',[_vm._v(_vm._s(_vm._f("formatNumber")(props.item.VlNf)))]),_c('td',[_vm._v(_vm._s(props.item.NmTransportadora))]),_c('td',[(props.item.FlXmlEnviado)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("mdi-email")])]}}],null,true)},[_c('span',[_vm._v("Email Enviado")])]):_vm._e(),(!props.item.FlXmlEnviado)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"default"}},on),[_vm._v("hourglass_top")])]}}],null,true)},[_c('span',[_vm._v("Aguardando Envio")])]):_vm._e(),(props.item.CdRetorno == '100')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('span',[_vm._v("NF-e Autorizado ("+_vm._s(props.item.CdRetorno)+")")])]):_vm._e(),(props.item.CdRetorno == '101')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("cancel")])]}}],null,true)},[_c('span',[_vm._v("NF-e Cancelada ("+_vm._s(props.item.CdRetorno)+")")])]):_vm._e(),(props.item.CdRetorno == '102')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("report_off")])]}}],null,true)},[_c('span',[_vm._v("NF-e Inutilizada ("+_vm._s(props.item.CdRetorno)+")")])]):_vm._e(),(props.item.CdRetorno == '302')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("block")])]}}],null,true)},[_c('span',[_vm._v("NF-e Denegada ("+_vm._s(props.item.CdRetorno)+")")])]):_vm._e(),(props.item.CdRetorno == '204')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("content_copy")])]}}],null,true)},[_c('span',[_vm._v("NF-e em Duplicidade ("+_vm._s(props.item.CdRetorno)+")")])]):_vm._e()],1),_c('td',[_c('div',[(props.item.FlEmitirMDF != '1')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"primary","small":""},nativeOn:{"click":function($event){return _vm.editar(props.item)}}},on),[_c('v-icon',[_vm._v("open_in_browser")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar MTR")])]):_vm._e(),(props.item.FlMTRGerado === '1')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"primary","small":""},nativeOn:{"click":function($event){return _vm.verMtr(props.item)}}},on),[_c('v-icon',[_vm._v("receipt_long")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver MTR")])]):_vm._e(),_c('danfe-view',{attrs:{"item":props.item,"textoBotao":"Ver DANFE"}}),(props.item.FlCteExiste == '1')?_c('dacte-view',{attrs:{"item":props.item}}):_vm._e(),(props.item.FlEmitirMDF == '1')?_c('mdf',{attrs:{"item":props.item}}):_vm._e(),(props.item.FlEmitirMDF == '1')?_c('documentos'):_vm._e()],1)])]}}]),model:{value:(_vm.complex.selected),callback:function ($$v) {_vm.$set(_vm.complex, "selected", $$v)},expression:"complex.selected"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }