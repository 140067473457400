var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"card":"","dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"px-5"},[_c('h4',{staticClass:"px-0"},[_vm._v(" Listagem de Manifestos Associados a esta NF-e ")])]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0 px-5"},[(_vm.$store.state.mfesVinculados.length > 0)?_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.$store.state.mfesVinculados),function(item,index){return [_c('v-list-tile',{key:item.title,attrs:{"avatar":"","ripple":""}},[_c('v-list-tile-content',{staticClass:"py-2"},[_c('v-list-tile-title',{staticClass:"text--primary pt-1"},[_c('b',[_vm._v("Número:")]),_vm._v(" "+_vm._s(item.NrMDF)+" - "),_c('b',[_vm._v("Chave:")]),_vm._v(" "+_vm._s(item.TxChAcessoMDFe)+" - "),_c('b',[_vm._v("Status:")]),_vm._v(" "+_vm._s(item.CdRetorno)+" "),(item.CdRetorno === '100')?_c('span',[_vm._v(" - Autorizado")]):_c('span',[_vm._v(" - "+_vm._s(item.objEncerramento.infEvento.xEvento))])]),_c('v-list-tile-sub-title',{staticClass:"text--primary pt-1"},[_vm._v("Data Emissão: "+_vm._s(_vm._f("formatDate")(item.DtEmissao,"DD/MM/YYYY"))+" "),(item.CdRetorno === '101')?_c('span',[_vm._v(" - Data de Cancelamento: "+_vm._s(_vm._f("formatDate")(item.DtEntrega,"DD/MM/YYYY HH:mm:ss"))+" ")]):_vm._e(),(item.CdRetorno === '132')?_c('span',[_vm._v(" - Data de Encerramento: "+_vm._s(_vm._f("formatDate")(item.DtEntrega,"DD/MM/YYYY HH:mm:ss"))+" ")]):_vm._e()]),_c('v-list-tile-sub-title',{staticClass:"text--primary pt-1"},[_vm._v("Placa: "+_vm._s(item.NmPlaca))])],1),_c('v-list-tile-action',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","fab":"","small":"","flat":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("more_vert")])],1)]}}],null,true),model:{value:(item.menuMDFe),callback:function ($$v) {_vm.$set(item, "menuMDFe", $$v)},expression:"item.menuMDFe"}},[_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Funções MDF-e")]),(item.CdRetorno === '100')?[_c('v-list-tile',[_c('v-list-tile-action',{on:{"click":function($event){return _vm.encerrar(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"primary","small":""},nativeOn:{"click":function($event){return _vm.encerrar(item)}}},on),[_c('v-icon',[_vm._v("mdi-truck-check-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Sinalizar como Entregue")])])],1),_c('v-list-tile-title',[_vm._v("Sinalizar como Entregue")])],1),_c('v-list-tile',[_c('v-list-tile-action',{on:{"click":function($event){return _vm.cancelar(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"primary","small":""},nativeOn:{"click":function($event){return _vm.cancelar(item)}}},on),[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancelar")])])],1),_c('v-list-tile-title',[_vm._v("Cancelar")])],1)]:_vm._e(),_c('v-list-tile',[_c('v-list-tile-action',[(item)?_c('mdfe-view',{attrs:{"mdf":item}}):_vm._e()],1),_c('v-list-tile-title',[_vm._v("Ver Manifesto")])],1),_c('v-list-tile',[_c('v-list-tile-action',{on:{"click":function($event){return _vm.consultar(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outline":"","icon":"","fab":"","dark":"","color":"primary","small":""},nativeOn:{"click":function($event){return _vm.consultar(item)}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,true)},[_c('span',[_vm._v("Consultar")])])],1),_c('v-list-tile-title',[_vm._v("Consultar")])],1)],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","small":"","color":"primary"},on:{"click":function($event){item.menuNFe = false}}},[_vm._v("Fechar")])],1)],1)],1)],1)],1),(index + 1 < _vm.$store.state.mfesVinculados.length)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e(),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }