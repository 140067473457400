<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h3>Listagem de Forncedores</h3>
        </v-flex>
        <v-flex lg12>
          <v-card>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Digite algo"
                v-model="search"
                hide-details
                class=""
                classeantiga="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
              >
                <template
                  slot="items"
                  slot-scope="props"
                  @click.native="editar(props.item)"
                >
                  <td>{{ props.item.CdFornec }}</td>
                  <td>{{ props.item.NmFornec }}</td>
                  <td>{{ props.item.CdCpfCgcFormatado }}</td>
                  <!-- <td>{{ props.item.comprador | nomeComprador }}</td> -->
                  <td>
                    <!-- <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="primary"
                      small
                      @click.native="editar(props.item)"
                    >
                      <v-icon>find_in_page</v-icon>
                    </v-btn> -->
                    <configuracao-fatura :item="props.item"></configuracao-fatura>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import toastr from "toastr";
import * as moment from "moment";
import "moment/locale/pt-br";
import ConfiguracaoFatura from "@/components/financeiro/ConfiguracaoFatura.vue";
moment.locale("pt-BR");

export default {
  name: "Fornecedores",
  components: {
    ConfiguracaoFatura,
  },
  data() {
    return {
      dialog: false,
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "CdFornec",
          },
          {
            text: "Fornecedor",
            value: "NmFornec",
          },
          {
            text: "CPF/CNPJ",
            value: "CdCpfCgc",
          },
          // {
          //   text: "Comprador",
          //   value: "comprador.nmComprador",
          // },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getList() {
      this.complex.items = [];
      toastr.success("Atualizando Lista de Fornecedores...", "Varejão da Sucata");
      this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/fornecedorList")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      this.$store.commit("abrirModalFornecedor", item);
      // window.getApp.$emit("APP_PRECO_PADRAO_FORNECEDOR_GET");
      // window.getApp.$emit("APP_TIPO_SUCATA_GET");
      // window.getApp.$emit("APP_TIPO_FRETE_GET");
    },
    excluir(item) {},
  },
  created() {
    this.getList();

    // window.getApp.$emit("APP_COMPRADORES_GET");
    // console.log(window.getApp._events)
    delete window.getApp._events.APP_FORNECEDORES_UPDATED;
    delete window.getApp._events.APP_RECORD_UPDATED;

    window.getApp.$emit("APP_METODO_PAGAMENTO_GET");
    window.getApp.$on("APP_FORNECEDORES_UPDATED", this.getList);
    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
  },
  updated() {
    //   this.getCategorias ()
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate(value, fmt = "D MMM YYYY") {
      return value == null ? "" : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
    nomeComprador(object) {
      // console.log(object)
      if (object) {
        return object.nmComprador;
      }
      // sorry for using lodash and ES2015 arrow functions :-P
      // return _.reject(object, (value) => value === null)
    },
  },
};
</script>
